import React from 'react';
import '../assess-attemption.css';
import ReactHtmlParser from 'react-html-parser';
import MyTinyEditor from '../../question-bank/create-question/tinymce-editor';

const DescriptiveQuestion = (props) => {
  const { questionObj: currentQuestionObj } = props || {};
  const { question_answer } = currentQuestionObj || {};
  const [{ question }] = question_answer;

  return (
    <div>
      <div className='mcq-question-wrapper'>
        <p className='descriptive_question_header'>{ReactHtmlParser(question)}</p>
        <MyTinyEditor
          id={`userId${currentQuestionObj?.id}`}
          content={currentQuestionObj?.user_response?.answer}
          placeholder='Answer...'
        />
      </div>
    </div>
  );
};

export default DescriptiveQuestion;

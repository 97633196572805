import React, { useState, useEffect , useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';



export default function AddUserDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [userLevelName, setUserLevelName] = useState('')
    const [userLevelId, setUserLevelId] = useState('')
    const { setAlert } = useContext(AlertNotificationContext);
    const [ idChange , setIdChange ] = useState(false)
    const [ levelDesc , setLevelDesc ] = useState('')


    const handleName = (e) => {
        setUserLevelName(e.target.value)
    }
    const handleId = (e) => {
        setUserLevelId(e.target.value)
        setIdChange(true)
    }

   useEffect(() => {
    if(props?.isEdit){
        setUserLevelName(props?.editData?.level_name)
        // setUserLevelId(props?.editData?.id)
        setLevelDesc(props?.editData?.description)
    }
    if(props?.isEdit === false){
        setUserLevelName('')
        // setUserLevelId('')
        setLevelDesc('')
    }
   },[props])

   const handleDesc = (e) => {
    setLevelDesc(e.target.value)
   }
 


    const handleSubmit = () => {
        let data = [];
        if (userLevelName.length > 3) {
            if(props.isEdit){
                     data = {
                        level_name: userLevelName,
                        description: levelDesc
                }
            } 
            if(!props.isEdit){
             data = {
                level_name: userLevelName,
                description: levelDesc
            }
        }
            if(props.isEdit){
                axiosInstance.put(`/central-admin/${props?.editData?.id}/user_level/`, data)
                .then((result) => {
                    setUserLevelName('')
                    if(result.data.status_code === 200){
                    setAlert('success','Added Successfully')
                    }
                    if(result.data.status_code === 400){
                        setAlert('error',result.data.message)
                        }
                    props.handleClose()
                    setIdChange(false)
                })
                .catch((error) => {
                    setAlert('error', 'failed to Add');
                })
            } else {
            axiosInstance.post(`${endpoints.user.addUserLevel}`, data)
                .then((result) => {
                    setUserLevelName('')
                    setAlert('success','Added Successfully')
                    props.handleClose()
                })
                .catch((error) => {
                    setAlert('error', 'failed to Add');
                })
            }
        } else {
            setAlert('warning','Minimum Length 3')
        }
    }

    return (
        <div>

            <Dialog
                open={props.openDialog}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Add User Level"}</DialogTitle>
              
                <DialogContent style={{minHeight: '80px'}} >
                    <TextField
                        id='domainname'
                        label='User Level Name'
                        variant='outlined'
                        size="small"
                        style={{ width: '100%' }}
                        value={userLevelName}
                        inputProps={{ maxLength: 30 }}
                        title='User Level Name'
                        name='domainname'
                        onChange={ handleName}
                    />
                     {/* <TextField
                        id='domainname'
                        label='User Level Id'
                        variant='outlined'
                        size="small"
                        style={{ width: '100%' , margin: '20px 0' }}
                        type="number"
                        value={userLevelId}
                        inputProps={{ maxLength: 10 }}
                        title='User Level Id'
                        name='domainname'
                        onChange={ handleId}
                    /> */}
                      <TextField
                        id='domainname'
                        label='User Level Description'
                        variant='outlined'
                        size="small"
                        style={{ width: '100%',  margin: '20px 0' }}
                        value={levelDesc}
                        inputProps={{ maxLength: 80 }}
                        title='User Level Description'
                        name='domainname'
                        onChange={ handleDesc}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary" variant='contained'
                        size="small"
                        style={{ color: 'white' }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

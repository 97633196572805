import { Button, Collapse } from 'antd';
import cuid from 'cuid';
import React, { useContext, useEffect, useState } from 'react';
import QuestionFilters from './questionFilters';
import { DeleteOutlined } from '@ant-design/icons';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { useDispatch } from 'react-redux';
import { addMarksToSection, addQuestionToSection } from '../../../../redux/actions';
import ConfirmPopOver from '../assessment-view/assesment-card/ConfirmPopOver';

const { Panel } = Collapse;
function CreateQuestion({
  onClose,
  section,
  questionId,
  grade,
  questionPaperWise,
  isCreation,
  setIsQuestionCreation,
  setCreateCount
}) {
  const { setAlert } = useContext(AlertNotificationContext);
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const [collapseKey, setCollapseKey] = useState('1');
  const [test_marks, setTestMarks] = useState(section?.test_marks);
  const [questionIds, setQuestionIds] = useState([]);

  const [filterArr, setFilterArr] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState();

  const [deleteId, setDeleteId] = useState('');
  const [deleteIndex, setDeleteIndex] = useState('');

  useEffect(() => {
    handleAddQuestion();
  }, []);


  useEffect(() => {
    if (isCreation && questions.length > 0) {
      // handleAdd()
      // handleCreate()
      submitData();
    }
    if(questions.length > 0){
      setCreateCount(questions.length)
    }
  }, [isCreation]);

  const handleCreate = () => {
    let requestBody = [];
    questions.forEach((item) => {
      let obj = {
        question_type: item?.question_type?.value,
        question_answer: item?.question_answer,
        question_level: item?.question_level?.id,
        question_categories: item?.question_categories?.id,
        chapter: item?.chapter?.value,
        topic: item?.topic?.value,
        grade_subject_mapping: item?.subject?.value,
        question_status: 2,
      };
      if (item?.question_type?.children == 'Comprehensive') {
        let subobjarr = []
        item.subquestions.forEach((childitem) => {
          if(childitem?.question_type?.value === 1 || childitem?.question_type?.value === 2){
            let subobj = {
              question_type: childitem?.question_type?.value,
              question_answer: childitem?.question_answer,
              question_level: childitem?.question_level?.id,
              question_categories: childitem?.question_categories?.id,
              chapter: childitem?.chapter?.value,
              topic: childitem?.topic?.value,
              grade_subject_mapping: childitem?.subject?.value,
              question_status: 2,
            };
            subobjarr.push(subobj)
          } else{
            let subobj = {
              question_type: childitem?.question_type,
              question_answer: childitem?.question_answer,
              question_level: childitem?.question_level?.id,
              question_categories: childitem?.question_categories?.id,
              chapter: childitem?.chapter?.value,
              topic: childitem?.topic?.value,
              grade_subject_mapping: childitem?.subject?.value,
              question_status: 2,
            };
            subobjarr.push(subobj)
          }
        })
        obj['sub_questions'] =  subobjarr;
      }
      // console.log('obj', obj)
      requestBody.push(obj);
    });
    axiosInstance
      .post(`${endpoints.createQuestionApis.createQuestionv1}`, requestBody)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          let Ids = result?.data?.result?.map((item) => item?.id);
          setQuestionIds(Ids);
          handleAddToSection(result?.data?.result);
          setAlert('success', result?.data?.message ?? 'Question created successfully');
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  useEffect(() => {
    if (questionIds.length) {
      let totalMarks = [...test_marks];
      questionIds.forEach((item, index) => {
        let marksObj = {
          question_id: item,
          question_mark: [parseFloat(questions[index].marks), 0],
          mark_type: 1,
          child_mark: [],
          is_central: true,
          ques_type: 1,
        };
        totalMarks.push(marksObj);
        setTestMarks([...test_marks], marksObj);
      });
      dispatch(addMarksToSection(totalMarks, questionId, section?.name));
      onClose();
    }
  }, [questionIds]);

  const handleAddToSection = (questionList) => {
    questionList.forEach((item, index) => {
      // handleMarkstosection(questions[index].marks,item)
      dispatch(addQuestionToSection(item, questionId, section?.name));
    });
  };

  // const handleMarkstosection = (marks,question) => {

  // let finalTestMarks = [...testMarks]
  // if(testMarks.length === 0){
  // SettestMarks(marksselection)
  // }
  // };

  const handleAddQuestion = () => {
    let que = {
      id: cuid(),
      subject: filterArr?.subject ?? '',
      module: filterArr?.module ?? '',
      question_type: '',
      question_answer: [{}],
      question_level: filterArr?.level ?? '',
      question_categories: filterArr?.category ?? '',
      chapter: filterArr?.chapter ?? '',
      topic: filterArr?.topic ?? '',
      grade: grade,
      marks: '',
      subquestions: [],
      is_central: true,
    };
    setQuestions([...questions, que]);
    setCreateCount([...questions, que].length)
    };

  const onChange = (key) => {
    setCollapseKey(key);
  };

  const handleDeleteQuestion = (id, index) => {
    let filteredQuestion = questions?.filter((item) => item?.id != id);
    if(filteredQuestion?.length < 1){
      filterArr.subject = ''
      filterArr.module = ''
      filterArr.chapter = ''
      filterArr.topic = ''
      filterArr.category = ''
      filterArr.level = ''
    }
    setQuestions(filteredQuestion);
    setCreateCount(filteredQuestion.length)
    setAlert('success', 'Question Deleted successfully')
  };

  // console.log(questions, 'questions');

  const submitData = () => {
    let validateArr = [];
    questions.map((valitem, index) => {
      let validateques = {};
      if (valitem.subject === '') {
        validateques['subject'] = false;
      } else {
        validateques['subject'] = true;
      }

      if (valitem.module === '') {
        validateques['module'] = false;
      } else {
        validateques['module'] = true;
      }

      if (valitem.chapter === '') {
        validateques['chapter'] = false;
      } else {
        validateques['chapter'] = true;
      }

      if (valitem.topic === '') {
        validateques['topic'] = false;
      } else {
        validateques['topic'] = true;
      }

      if (valitem.question_categories === '') {
        validateques['question_categories'] = false;
      } else {
        validateques['question_categories'] = true;
      }

      if (valitem.question_level === '') {
        validateques['question_level'] = false;
      } else {
        validateques['question_level'] = true;
      }

      if (valitem.question_type === '') {
        validateques['question_type'] = false;
      } else {
        validateques['question_type'] = true;
      }

      if (!questionPaperWise) {
        if (valitem.marks === '') {
          validateques['marks'] = false;
        } else {
          validateques['marks'] = true;
        }
      }

      if (
        valitem.question_answer[0].question === '' ||
        valitem.question_answer[0].question === undefined
      ) {
        validateques['question'] = false;
      } else {
        validateques['question'] = true;
      }

      //QUESTION TYPE DESCRIPTIVE AND TRUE FALSE
      if (valitem.question_type?.value === 10 || valitem.question_type?.value === 8) {
        if (
          valitem.question_answer[0].answer === '' ||
          valitem.question_answer[0].answer === undefined
        ) {
          validateques['answer'] = false;
        } else {
          validateques['answer'] = true;
        }
      }

      //QUESTION TYPE FILL IN THE BLANKS
      if (valitem.question_type?.value === 9) {
        let options = valitem.question_answer[0]?.options;
        if (options !== undefined) {
          options.map((item, index) => {
            if (item[`option${index + 1}`].optionValue === '') {
              validateques['answer'] = false;
            } else {
              validateques['answer'] = true;
            }
          });
        } else {
          validateques['answer'] = false;
        }
      }

      //QUESTION TYPE MCQ
      if (valitem.question_type?.value === 2) {
        let options = valitem.question_answer[0]?.options;
        if (options !== undefined) {
          options.map((item, index) => {
            if (
              item[`option${index + 1}`].optionValue === '' ||
              valitem.question_answer[0].answer === '' ||
              valitem.question_answer[0].answer === undefined ||
              valitem.question_answer[0].answer?.length < 2
            ) {
              validateques['answer'] = false;
            } else {
              validateques['answer'] = true;
            }

            
          });
        } else {
          validateques['answer'] = false;
        }
      }

      //QUESTION TYPE COMPREHENSIVE
      if (valitem.question_type?.value === 7) {
        if (valitem.subquestions === '' || valitem.subquestions?.length < 1) {
          validateques['subquestions'] = false;
        } else {
          let subquestion = valitem.subquestions;
          let subvalidateArr = [];
          subquestion.map((subitem, subindex) => {
            let subvalidateques = {};
            if (
              subitem.question_answer[0].question === '' ||
              subitem.question_answer[0].question === undefined
            ) {
              subvalidateques['question'] = false;
            } else {
              subvalidateques['question'] = true;
            }

            //QUESTION TYPE DESCRIPTIVE AND TRUE FALSE INSIDE COMPREHENSIVE
            if (subitem.question_type === 10 || subitem.question_type === 8) {
              if (
                subitem.question_answer[0].answer === '' ||
                subitem.question_answer[0].answer === undefined
              ) {
                subvalidateques['answer'] = false;
              } else {
                subvalidateques['answer'] = true;
              }
            }

            //QUESTION TYPE FILL IN THE BLANKS INSIDE COMPREHENSIVE
            if (subitem.question_type === 9) {
              let suboptions = subitem.question_answer[0]?.options;
              if (suboptions !== undefined) {
                suboptions.map((opitem, opindex) => {
                  if (opitem[`option${opindex + 1}`].optionValue === '') {
                    subvalidateques['answer'] = false;
                  } else {
                    subvalidateques['answer'] = true;
                  }
                });
              } else {
                subvalidateques['answer'] = false;
              }
            }

            //QUESTION TYPE MCQ INSIDE COMPREHENSIVE
            if (subitem.question_type === 2) {
              let suboptions = subitem.question_answer[0]?.options;
              if (suboptions !== undefined) {
                suboptions.map((opitem, opindex) => {
                  if (
                    opitem[`option${opindex + 1}`].optionValue === '' ||
                    subitem.question_answer[0].answer === '' ||
                    subitem.question_answer[0].answer === undefined ||
                    subitem.question_answer[0].answer?.length < 1
                  ) {
                    validateques['answer'] = false;
                  } else {
                    validateques['answer'] = true;
                  }
                });
              } else {
                validateques['answer'] = false;
              }
            }

            subvalidateArr.push(subvalidateques);
          });
          let subfinalArr = subvalidateArr.map((each) => {
            let value = Object.values(each);
            if (value.every((item) => item === true)) {
              return true;
            } else {
              return false;
            }
          });

          if (subfinalArr.every((item) => item === true)) {
            validateques['question'] = true;
          } else {
            validateques['question'] = false;
          }
        }
      }

      validateArr.push(validateques);
    });

    let finalArr = validateArr.map((each) => {
      let value = Object.values(each);
      if (value.every((item) => item === true)) {
        return true;
      } else {
        return false;
      }
    });

    if (finalArr.every((item) => item === true)) {
      handleCreate();
      setIsQuestionCreation(false);
    } else {
      setIsQuestionCreation(false);
      setAlert('error', 'Please fill all data');
    }
  };

  return (
    <>
      <div className='row align-items-center th-bg-blue-1 py-1 mb-2'>
        <div className='col-md-8 th-fw-700 '>Section {section?.name}</div>
      </div>
      <div>
        {questions?.map((item, index) => (
          <div key={index} className='my-2'>
            <Collapse accordion>
              <Panel collapsible={true} header={`Question ${index + 1}`} key={index}>
                {/* <div>
        Question {index + 1}
            </div> */}
                <div className='row'>
                  <div className='row mt-2 ml-1 py-2'>Select Filter</div>
                  <QuestionFilters
                    parentIndex={index}
                    question={item}
                    grade={grade}
                    questionList={questions}
                    setQuestions={setQuestions}
                    questionTypeProps={questions?.[index]?.question_type}
                    questionPaperWise={questionPaperWise}
                    filterArr={filterArr}
                    setFilterArr={setFilterArr}
                  />
                  <div
                    className='mt-3'
                    style={{
                      display: 'flex',
                      marginRight: '1%',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'end',
                    }}
                  >
                    <DeleteOutlined
                      title='Delete Question'
                      style={{ color: 'blue', fontSize: 20, cursor: 'pointer' }}
                      onClick={() => {
                        // handleDeleteQuestion(item.id, index)
                        setDeleteId(item.id)
                        setDeleteIndex(index)
                        setConfirmMessage('delete');
                        setOpenModal(true)
                      }}
                    />

                    {openModal && (
                      <ConfirmPopOver
                        submit={() => handleDeleteQuestion(deleteId, deleteIndex)}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        operation={confirmMessage}
                        opendelete={true}
                      />
                    )}
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        ))}
      </div>

      <div className='d-flex justify-content-end mr-2'>
        <Button className='th-button-active' onClick={handleAddQuestion}>
          Add Question
        </Button>
      </div>
    </>
  );
}

export default CreateQuestion;

import React from 'react';
import '../assess-attemption.css';
import ReactHtmlParser from 'react-html-parser';
import MyTinyEditor from '../../question-bank/create-question/tinymce-editor';

const VideoQuestion = (props) => {
  const { questionObj: currentQuestionObj } = props || {};

  const { question_answer } = currentQuestionObj || {};

  const [{ question, video }] = question_answer;

  return (
    <div>
      <div className='mcq-question-wrapper'>
        <div>{ReactHtmlParser(question)}</div>
        <video width='100%' height='500' controls>
          <source src={video} type='video/mp4' />
        </video>

        <MyTinyEditor
          id={`userId${currentQuestionObj?.id}`}
          content={currentQuestionObj?.user_response?.answer}
          placeholder='Question.'
        />
      </div>
    </div>
  );
};

export default VideoQuestion;

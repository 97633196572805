import React, { useState, useEffect } from 'react';
import MyTinyEditor from '../../assesment/assessment-new/create-question-paper-new/tiny-mce-new';
import QuestionCard from './QuestionCard';
import cuid from 'cuid';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const CreateComprehensionQuestion = ({
  currentQuestion,
  questionTypeList,
  handleQuestionChanges,
  handleDeleteSubQuestion,
}) => {
  const [comprehensionQuestion, setComprehensionQuestion] = useState(currentQuestion);

  const handleComprehensionContent = () => {
    return currentQuestion?.question_answer[0]?.question;
  };
  const handleEditorChange = (content, editor) => {
    handleQuestionChanges({ question: content });
  };
  return (
    <div>
      <div className='col-12'>
        <div className='th-16 th-fw-600 th-black'>Question</div>
        <div className='th-editor'>
          <MyTinyEditor
            id={`comprehensivemainquestion`}
            content={handleComprehensionContent()}
            handleEditorChange={handleEditorChange}
            // setOpenEditor={true}
            placeholder='Question goes here...'
          />
        </div>
      </div>

      {currentQuestion?.sub_questions?.map((item, index) => {
        return (
          <div className='pt-2 px-3'>
            <QuestionCard
              questionTypeList={questionTypeList}
              chapterId={currentQuestion?.chapter}
              keyConceptId={currentQuestion?.topic}
              subjectId={currentQuestion?.grade_subject_mapping}
              isSubQuestion={true}
              subQuestionIndex={index}
              key={cuid()}
              question={currentQuestion}
            />
            {currentQuestion?.sub_questions.length > 1 && (
              <div className='py-2 text-center px-1'>
                {' '}
                <Button
                  className='th-bg-grey th-red'
                  onClick={() => {
                    handleDeleteSubQuestion(index);
                  }}
                >
                  <DeleteOutlined className='pr-2 th-red' /> Delete sub question{' '}
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CreateComprehensionQuestion;

import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Addicon from '../../assets/images/Add.svg';
import { SvgIcon } from '@material-ui/core';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import { FormHelperText } from '@material-ui/core';

// import './mapping.css';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Createnew(props) {
  const { setAlert } = useContext(AlertNotificationContext);
  const {
    schools,
    handleSchoolChange,
    selectedSchool = {},
    modules,
    selectedModule,
    handleModuleChange,
    setSchool,
    selectModule,
    resetFlag,
    setResetFlag,
    sessionYear,
    setSessionYear,
    handleResetData,
  } = props;
  const [open, setOpen] = React.useState(false);
  // const [school, setSchool] = React.useState('');
  const [grade, setGrade] = React.useState('');
  const [sessionYearDropdown, setSessionYearDropdown] = React.useState([]);
  // const [sessionYear, setSessionYear] = React.useState(null);
  const [subjectAndGrade, setSubjectGrade] = React.useState([]);
  const [gradeDropdpwn, setGradeDropdown] = React.useState([]);
  const [subjectDropdpwn, setSubjectDropdown] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [versionData, setVersionData] = useState();
  const [versionValue, setVersion] = useState();
  const [gradeSubject, setGradeSubject] = useState();
  const [selectedGrade, setSelectedGrade] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setResetFlag(false);
    handleResetData();
    setSubjectGrade([]);
    setSelectedGrade();
  };

  const handlesubgrade = (value) => {
    setGradeSubject(value);
  };

  console.log(resetFlag, 'rese');

  useEffect(() => {
    if (resetFlag == true) {
      setSessionYear(null);
      setSessionYearDropdown([]);
      setVersion();
      setVersionData();
      handleSessionYearChange(null);
      console.log('hit');
      setSubjectGrade([]);
      setGradeSubject();
      setSubjectDropdown([]);
      setResetFlag(false);
      setSelectedGrade();
    }
  }, [resetFlag]);

  const getVersion = (value) => {
    axiosInstance
      .get(
        `${endpoints.masterManagement.versionData}?school=${selectedSchool?.id}&${value?.key}=true`
      )
      .then((result) => {
        setVersionData(result?.data?.result?.result);
        setSessionYearDropdown(result?.data?.result?.result[0]?.school_versions);
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  // useEffect(() => {
  //   if (versionData?.is_version_exist == true && sessionYearDropdown?.length > 0) {
  //     if (selectedModule != []) {
  //       setSessionYear(sessionYearDropdown[0]);
  //       console.log('hit set');
  //     }
  //   }
  // }, [versionData, sessionYearDropdown]);

  useEffect(() => {
    getVersion();
  }, []);

  useEffect(() => {
    if (sessionYearDropdown?.length > 0) {
      if (selectedModule != []) {
        setSessionYear(sessionYearDropdown[0]);
        console.log('hit set');
      }
    }
  }, [versionData, sessionYearDropdown]);

  const moduleChange = (e, value) => {
    console.log(value);
    getVersion(value);
    handleModuleChange(e, value);
  };

  useEffect(() => {
    const getGradeDropdown = () => {
      axiosInstance
        .get(endpoints.mapping.gradelist)
        .then((res) => {
          setGradeDropdown(res.data.result.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getGradeDropdown();

    // axiosInstance
    //   .get(`${endpoints.masterManagement.academicYear}`)
    //   .then((result) => {
    //     if (result.data.status_code === 200) {
    //       {
    //         setSessionYearDropdown(result.data.result.results);
    //       }
    //     } else {
    //       setAlert('error', result.data.error_message);
    //     }
    //   })
    //   .catch((error) => {
    //     setAlert('error', error.message);
    //   });
  }, []);

  // const handleSchoolChange = (value) => {
  //     if (value && value.id !== null) {
  //         setSchool(value.id)
  //     } else {
  //         setSchool('')
  //     }
  // }
  const handleSessionYearChange = (value) => {
    setSessionYear(value);
  };
  const handleGradeChange = (values) => {
    if (values && values.id !== null) {
      setGrade(values.id);
      axiosInstance
        .get(`${endpoints.mapping.subjectList}?grade=${values.id}`)
        .then((res) => {
          const { results } = res.data.result;
          let subj = [];
          for (const i of results) {
            subj.push({
              subject: i.subject.subject_name,
              id: i.id,
            });
          }
          setSubjectDropdown(subj);
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    } else {
      setGrade('');
    }
  };

  const handleSubjectChange = (values) => {
    let value = Array.from(values, (option) => option.id);
    setSubjectGrade(value);
  };

  const handleSubmit = () => {
    if (selectedSchool && selectedModule && selectedGrade) {
      const { key: moduleKey, value } = selectedModule || {};
      const { id: schoolId } = selectedSchool || {};
      let body = {
        school: schoolId,
        grade_subject_mapping: subjectAndGrade,
        module: moduleKey,
        [moduleKey]: value,
      };
      if (selectedModule?.id !== 'assessment') {
        if (sessionYear == null) {
          setAlert('error', 'Please Select Version');
          return;
        } else {
          body['academic_year'] = sessionYear?.academic_year;
        }
      }
      if (
        schoolId &&
        moduleKey &&
        subjectAndGrade?.length &&
        selectedGrade &&
        gradeSubject
      ) {
        // const valid = Validation(body);
        // if (valid.isValid === true) {
        axiosInstance
          .post(endpoints.mapping.schoolList, body)
          .then((res) => {
            console.log(res, 'res');
            // setAlert('success', res.data.message);
            setAlert('success', 'Mapping successfully added ');
            handleClose();
          })
          .catch((err) => {
            console.log(err, 'err');
            setAlert('error', err.data.message);
          });

        // } else {
        //     console.log(valid, "valid")
        //     setError(valid.error)
        //     return false;
        // }
      } else {
        setAlert('warning', 'Please choose all fields.');
        return;
      }
    } else {
      setAlert('warning', 'Please Select all fields.');
    }
  };

  const Validation = (formData) => {
    let input = formData;
    let error = {};
    let isValid = true;
    if (!input['school']) {
      isValid = false;
      error['schoolError'] = 'Please select valid school';
    }
    if (
      input['grade_subject_mapping'].length === 0 ||
      input['grade_subject_mapping'] === ''
    ) {
      isValid = false;
      error['grade_subject_mapping'] = 'Please select valid subject';
    }
    if (!input['module']) {
      isValid = false;
      error['moduleError'] = 'Please select module';
    }

    const validInfo = {
      error,
      isValid,
    };
    return validInfo;
  };

  const { grade_subject_mapping, schoolError, moduleError } = error;
  return (
    <div>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        <SvgIcon
          component={() => (
            <img
              style={{ width: '12px', marginRight: '5px' }}
              src={Addicon}
              alt='given'
            />
          )}
        />
        {/* Assign Role */}
        Add Mapping
      </Button>
      <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Mapping
        </DialogTitle>
        <DialogContent dividers>
          {/* <div className="create-new-form"> */}
          {/* <div className="school-name"> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }}>
                <Autocomplete
                  // style={{ width: 569 }}
                  style={{ width: '100%' }}
                  // multiple
                  required={true}
                  id='tags-outlined'
                  options={schools}
                  value={selectedSchool}
                  getOptionLabel={(option) => option.school_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='School' />
                  )}
                  // onChange={(e, value) => {
                  //     handleSchoolChange(value);
                  // }}
                  onChange={handleSchoolChange}
                  getOptionSelected={(option, value) => value && option.id == value.id}
                />
                <FormHelperText id='my-helper-text' style={{ color: 'red' }}>
                  {schoolError}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }}>
                <Autocomplete
                  // style={{ width: 569 }}
                  style={{ width: '100%' }}
                  // multiple
                  required={true}
                  id='tags-outlined'
                  options={modules}
                  value={selectedModule}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Module' />
                  )}
                  // onChange={(e, value) => {
                  //     handleSchoolChange(value);
                  // }}
                  onChange={moduleChange}
                  getOptionSelected={(option, value) => value && option?.id == value?.id}
                />
                <FormHelperText id='my-helper-text' style={{ color: 'red' }}>
                  {moduleError}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* Session Year */}
            {selectedModule.id !== 'assessment' && (
              <Grid item xs={12}>
                <FormControl required style={{ width: '100%' }}>
                  <Autocomplete
                    // style={{ width: 569 }}
                    // multiple
                    // required={true}
                    value={sessionYear}
                    // disabled={versionData?.is_version_exist || false}
                    id='tags-outlined'
                    options={sessionYearDropdown || []}
                    getOptionLabel={(option) => option?.version_name}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='Version' />
                    )}
                    onChange={(e, value) => {
                      handleSessionYearChange(value);
                    }}
                    getOptionSelected={(option, value) =>
                      value && option?.academic_year == value?.academic_year
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {/* </div> */}
            {/* <div className="grade-new" style={{ marginTop: '20px' }}> */}
            <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }}>
                <Autocomplete
                  // style={{ width: 569 }}
                  // multiple
                  value={selectedGrade}
                  key={Date.now()?.toLocaleString()}
                  required={true}
                  id='tags-outlined'
                  options={gradeDropdpwn}
                  getOptionLabel={(option) => option.grade_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Grade' />
                  )}
                  onChange={(e, value) => {
                    handleGradeChange(value);
                    setSelectedGrade(value);
                  }}
                  getOptionSelected={(option, value) => value && option.id == value.id}
                />
              </FormControl>
            </Grid>
            {/* </div> */}
            {/* <div className="subject-new" style={{ marginTop: '20px' }}> */}
            <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }}>
                <Autocomplete
                  // style={{ width: 569 }}
                  key={Date.now()?.toLocaleString()}
                  value={gradeSubject}
                  style={{ width: '100%' }}
                  multiple
                  required={true}
                  id='tags-outlined'
                  options={subjectDropdpwn}
                  getOptionLabel={(option) => option.subject}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Subject' />
                  )}
                  onChange={(e, value) => {
                    handleSubjectChange(value);
                    handlesubgrade(value);
                  }}
                  getOptionSelected={(option, value) => value && option.id == value.id}
                />
                <FormHelperText id='my-helper-text' style={{ color: 'red' }}>
                  {grade_subject_mapping}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {/* </div> */}

          {/* </div> */}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant='contained'
            color='primary'
            style={{ color: 'white' }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  IconButton,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Menu,
  SvgIcon,
} from '@material-ui/core';
import Layout from '../../Layout';
// import { Paper } from "../../../utility/ui";
import styles from './style';
// import { constantValue } from "../../../utility/helper/getConstant";
import { constantValue } from '../../../getConstant';
// import { useAlert } from "../../../utility/customHooks";
// import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';

// import checkObjectsEqual from "../../../utility/helper/checkObjectEqual";
import { checkObjectsEqual, getArrayValues } from '../../../utility-functions/index';
// import useWindowDimensions from "../../../utility/customHooks/useWindowDimensions";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import QuestionPreview from './preview/questionPreview';
import ReactToPrint from 'react-to-print';
import Loading from '../../../components/loader/loader';
import {
  Close,
  Equalizer,
  Schedule,
  AccessTime,
  SignalCellularAlt,
  Receipt,
  Visibility,
  Edit,
  Delete,
  MoreHoriz,
  Description,
} from '@material-ui/icons';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Tooltip } from '@mui/material';
import Modal from '../../../components/commonModal';
import DurationIcon from '../../../assets/images/duration.png';
import DifficultyIcon from '../../../assets/images/difficulty.png';
import MarksIcon from '../../../assets/images/marks.png';
import QuestionCountIcon from '../../../assets/images/questionCount.png';
// import { scales } from "chart.js";
// import ChooseTemplate from './preview/chooseTemplate';
import '../CreateTemplate/general.scss';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { Autocomplete, Pagination, PaginationItem } from '@material-ui/lab';
import moment from 'moment';
import QuestionPreview from '../CreateTemplate/preview/questionPreview';
import AnswerPreview from '../CreateTemplate/preview/answersheetPrint';
import ChooseTemplate from '../CreateTemplate/preview/chooseTemplate';
import DeleteDialog from '../../../components/deleteDialog';
import noData from '../../../assets/images/noData.svg';
import unfiltered from '../../../assets/images/unfiltered.svg';

const statusFilter = [
  { name: 'All', id: 0 },
  { name: 'Published', id: 2 },
  { name: 'For Review', id: 3 },
  { name: 'Draft', id: 1 },
  { name: 'Deleted', id: 4 },
];

const QuestionPaper = ({ classes }) => {
  const history = useHistory();
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('md'));
  let printRef = useRef();
  let amswerRef = useRef();

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterDate] = useState({
    academic_year: null,
    grade: null,
    subject: null,
    status: 0,
  });
  const [academicYearList, setAcademicYearList] = useState(null);
  const [gradeList, setGradeList] = useState(null);
  const [subjectList, setSubjectList] = useState(null);
  const [accessLabelList, setAccessLabelList] = useState([]);
  const [alltemplatePageData, setAllTemplatePageData] = useState({
    currentPage: 1,
    pageSize: 6,
    totalPage: null,
    totalCount: null,
  });
  const [isLocation, setIsLocation] = useState(false);
  const [allQuestionPaper, setAllQuestionPaper] = useState(null);
  const [questionPreviewModalOpen, setQuestionPreviewModalOpen] = useState({
    open: false,
    data: null,
  });
  const [deleteQuestionPaperModalOpen, setDeleteQuestionPaperModalOpen] = useState({
    open: false,
    data: null,
  });
  const [restoreQuestionPaperModalOpen, setRestoreQuestionPaperModalOpen] = useState({
    open: false,
    data: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [printAnswer, setPrintAnswer] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    console.log({ allQuestionPaper, alltemplatePageData, filterData }, 'alllog');
  });

  useEffect(() => {
    let data = history?.location?.state?.data;
    if (data) {
      setFilterDate(data?.filterData);
      setAllTemplatePageData({ ...alltemplatePageData, currentPage: data?.page });
      setIsLocation(true);
    }
  }, [history.location.state]);

  useEffect(() => {
    getAllQuestionPaper();
    getAcademicYear();
    getSubjectList();
    getAccessLabelList();
  }, []);

  useEffect(() => {
    getGradeList();
  }, [filterData.academic_year]);

  useEffect(() => {
    getSubjectList();
  }, [filterData.grade]);

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpenIndex(null);
  };

  const getAcademicYear = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          {
            setAcademicYearList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  const getGradeList = () => {
    // setGradeList(constantValue.grades);
    if (filterData.academic_year !== null) {
      axiosInstance
        .get(`${endpoints.masterManagement.grades}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            {
              setGradeList(result.data.result.results);
            }
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  };

  const getSubjectList = () => {
    // setSubjectList(constantValue.subjects);
    if (filterData.grade !== null) {
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
          params: { grade: filterData.grade?.id },
        })
        .then((result) => {
          if (result.data.status_code === 200) {
            {
              setSubjectList(result.data.result.results);
            }
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  };
  const getAccessLabelList = () => {
    axiosInstance
      .get(`${endpoints.user.getUserLevel}`, {
        params: {
          exclude_student: true,
        },
      })
      .then((res) => {
        setAccessLabelList(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllQuestionPaper = (pageNo, statusId) => {
    // setAllTemplates([...constantValue.alltemplates]);
    setIsLocation(false);
    setLoading(true);
    axiosInstance
      .get(`${endpoints.assementQP.listAutoQp}`, {
        params: {
          // id:4
          // page: pageNo,
          page: pageNo !== undefined ? pageNo : alltemplatePageData.currentPage,
          page_size: alltemplatePageData.pageSize,
          academic_year: filterData.academic_year?.id,
          grade: filterData?.grade?.id,
          subject: filterData.subject?.subject?.id,
          status:
            statusId !== undefined
              ? [0, 4].includes(statusId)
                ? null
                : statusId
              : [0, 4].includes(filterData?.status)
              ? null
              : filterData?.status,

          is_delete:
            statusId !== undefined
              ? statusId === 4
                ? 'True'
                : [1, 2, 3].includes(statusId)
                ? 'False'
                : null
              : filterData?.status === 4
              ? 'True'
              : [1, 2, 3].includes(filterData?.status)
              ? 'False'
              : null,
        },
      })
      .then((res) => {
        console.log(res, 'qpTemplate res');
        if (res.data.status_code === 200) {
          setAllTemplatePageData({
            ...alltemplatePageData,
            currentPage: pageNo !== undefined ? pageNo : alltemplatePageData.currentPage,
            totalCount: res.data.result.count,
            totalPage: Math.ceil(res.data.result.count / alltemplatePageData.pageSize),
          });
          let tempAllTemplate = res.data.result.results.map((each) => {
            let tempEachAllTemplate = {
              ...each,
              title: each.paper_name,
              subject:
                each?.subjects?.length > 0
                  ? {
                      id: each?.subjects[0]?.grade_subject_mapping,
                      subject: each?.subjects[0],
                    }
                  : null,

              section: each.section
                .filter((eachSec, index) => index < each.section.length - 1)
                .map((eachSection, index) => {
                  return {
                    ...eachSection,
                    header: eachSection?.header[0],
                    description: eachSection?.description[0],
                    totalQuestion: eachSection?.totalQuestion[0],
                    question: eachSection?.question.map((eachQuestion, qIndex) => {
                      return {
                        // ...eachQuestion,
                        bloom: {
                          id: eachQuestion?.question_categories,
                          category_name: eachQuestion?.question_categories_display,
                        },
                        difficult: {
                          id: eachQuestion?.question_level,
                          level_name: eachQuestion?.question_level_display,
                        },
                        type: {
                          id: eachQuestion?.question_type_sub_type_id,
                          type_name: eachQuestion?.question_type_sub_type_name,
                        },
                        subType: {
                          id: eachQuestion?.question_type,
                          type_name: eachQuestion?.question_type_name,
                          sub_type_id: eachQuestion?.question_type_sub_type_id,
                        },
                        marks: eachQuestion?.question_mark[0],
                        question: eachQuestion,
                      };
                    }),
                  };
                }),
              // volume: each?.volume ? each?.volume : [], //need
              // chapter: each?.chapter ? each?.chapter : [], //need
              volume: each.section[each.section.length - 1].vol_details,
              chapter: each.section[each.section.length - 1].chap_details, //need
              total_marks: each.total_mark,
              duration: each.duration ? each?.duration : null, //need
              instruction: each?.instructions ? each?.instructions : '', //need
              questionGenerate: true,
              currentPaper: 1,
              exportType: 'Draft',
              // // subject: each?.subjects[0],
              // duration: each?.total_duration,
              // instruction: each?.instructions,
            };
            return { ...tempEachAllTemplate };
          });
          console.log(tempAllTemplate, 'tempAllTemplate');
          setAllQuestionPaper(tempAllTemplate);

          //   setAllTemplates(res.data.result.results);
        } else {
          console.log('error erroe');
          setAlert('error', res.data.message);
          setAllQuestionPaper(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, 'qpTemplate err');
      });
  };

  useEffect(() => {
    if (isLocation) {
      getAllQuestionPaper();
    }
  }, [isLocation]);

  const handleAllTemplatePagination = (event, value) => {
    setAllTemplatePageData({
      ...alltemplatePageData,
      currentPage: value,
    });
    getAllQuestionPaper(value);
  };

  const changeStatusQuestionPaper = (questionPaperId, oldStatus, newStatus) => {
    if (questionPaperId && oldStatus && newStatus) {
      axiosInstance
        .put(`${endpoints.assementQP.questionPaperStatusChange}${questionPaperId}`, {
          // is_delete: false,
          status: newStatus,
        })
        .then((res) => {
          console.log(res, 'qpTemplate res');
          if (allQuestionPaper.length === 1 && alltemplatePageData.currentPage !== 1) {
            let tempCurrentPage = alltemplatePageData.currentPage;
            setAllTemplatePageData({
              ...alltemplatePageData,
              currentPage: tempCurrentPage - 1,
            });
            getAllQuestionPaper(tempCurrentPage - 1);
          } else {
            getAllQuestionPaper();
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
        });
    }
  };

  const deleteQuestionPaper = (questionPaperId) => {
    if (questionPaperId) {
      axiosInstance
        .delete(`${endpoints.assementQP.listAutoQp}`, {
          params: {
            id: questionPaperId,
          },
        })
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setDeleteQuestionPaperModalOpen({ open: false, data: null });
          if (allQuestionPaper.length === 1 && alltemplatePageData.currentPage !== 1) {
            let tempCurrentPage = alltemplatePageData.currentPage;
            setAllTemplatePageData({
              ...alltemplatePageData,
              currentPage: tempCurrentPage - 1,
            });
            getAllQuestionPaper(tempCurrentPage - 1);
          } else {
            getAllQuestionPaper();
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
        });
    }
  };
  const restoreQuestionPaper = (questionPaperId) => {
    if (questionPaperId) {
      axiosInstance
        .put(`${endpoints.assementQP.questionPaperStatusChange}${questionPaperId}`, {
          is_delete: false,
          status: 1,
        })
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setRestoreQuestionPaperModalOpen({ open: false, data: null });
          if (allQuestionPaper.length === 1 && alltemplatePageData.currentPage !== 1) {
            let tempCurrentPage = alltemplatePageData.currentPage;
            setAllTemplatePageData({
              ...alltemplatePageData,
              currentPage: tempCurrentPage - 1,
            });
            getAllQuestionPaper(tempCurrentPage - 1);
          } else {
            getAllQuestionPaper();
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
        });
    }
  };

  let questionPreviewModal = null;
  if (questionPreviewModalOpen.open) {
    questionPreviewModal = (
      <Modal
        open={questionPreviewModalOpen.open}
        click={() => setQuestionPreviewModalOpen({ open: false, data: null })}
        medium
        style={isMobile ? { padding: '0px 15px ', width: 280 } : { padding: '0px 15px' }}
      >
        <React.Fragment>
          <Grid
            container
            spacing={1}
            justify='space-between'
            style={{ position: 'relative' }}
          >
            <Grid
              item
              xs={12}
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '10px 0px ',
              }}
            >
              <Grid container spacing={2} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography className='font-size-16'>
                    <strong>Question Paper Preview</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() =>
                      setQuestionPreviewModalOpen({ open: false, data: null })
                    }
                  >
                    <Close style={{ height: 18, width: 18 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 10, paddingBottom: 20 }}>
              <Grid item xs={12}>
                <QuestionPreview
                  templateFrom={questionPreviewModalOpen?.data}
                  currentStep={4}
                  isQuestionPaper={true}
                />
                {/* <ChooseTemplate
                    templateFrom={questionPreviewModalOpen?.data}
                    currentStep={3}
                  /> */}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  let deleteQuestionPaperModal = null;
  if (deleteQuestionPaperModalOpen.open) {
    deleteQuestionPaperModal = (
      <DeleteDialog
        showModal={deleteQuestionPaperModalOpen.open}
        hideModalHandler={() =>
          setDeleteQuestionPaperModalOpen({ open: false, data: null })
        }
        dialogText='Are You Sure To Delete Question Paper ?'
        // successMethod={deleteTemplate(deleteQuestionPaperModalOpen.data)}
        successMethod={() => {
          deleteQuestionPaper(deleteQuestionPaperModalOpen.data);
        }}
      />
    );
  }
  let restoreQuestionPaperModal = null;

  if (restoreQuestionPaperModalOpen.open) {
    restoreQuestionPaperModal = (
      <DeleteDialog
        showModal={restoreQuestionPaperModalOpen.open}
        hideModalHandler={() =>
          setRestoreQuestionPaperModalOpen({ open: false, data: null })
        }
        dialogText='Are You Sure To Restore Question Paper ?'
        // successMethod={deleteTemplate(deleteQuestionPaperModalOpen.data)}
        successMethod={() => {
          restoreQuestionPaper(restoreQuestionPaperModalOpen.data);
        }}
      />
    );
  }

  return (
    <>
      <Layout>
        {loading ? <Loading message='Loading...' /> : null}
        <div className={`${classes.mainContainer}`}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
            spacing={1}
          >
            <Grid item>
              <Typography style={{ fontWeight: '600' }}>
                Auto Assessment - Question Paper
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={() => {
                  history.replace({
                    pathname: '/auto-assessment/question-paper',
                    state: {
                      data: {
                        filterData: filterData,
                        page: alltemplatePageData?.currentPage,
                      },
                    },
                  });
                  history.push({
                    pathname: `/auto-assessment/create-template/`,
                    state: {
                      data: {
                        previousURL: window.location.pathname,
                      },
                    },
                  });
                }}
              >
                Create Question Paper
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container direction='row' alignItems='center' spacing={2}>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={1}>
                <Grid item sm={3} md={2} xs={6}>
                  <Autocomplete
                    className={[classes.autoCompleate]}
                    options={academicYearList || []}
                    getOptionLabel={(option) => option.session_year}
                    onChange={(e, newValue) => {
                      setFilterDate({
                        ...filterData,
                        academic_year: newValue,
                        grade: null,
                        subject: null,
                      });
                      setGradeList(null);
                      setSubjectList(null);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={filterData.academic_year}
                    size='small'
                    variant='outlined'
                    margin='dense'
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder='Academic Year'
                        variant='outlined'
                        margin='dense'
                        name='academic_year'
                        className={[classes.textField]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} md={2} xs={6}>
                  <Autocomplete
                    className={[classes.autoCompleate]}
                    options={gradeList || []}
                    getOptionLabel={(option) => option.grade_name}
                    onChange={(e, newValue) => {
                      setFilterDate({
                        ...filterData,
                        grade: newValue,
                        subject: null,
                      });
                      setSubjectList(null);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={filterData.grade}
                    size='small'
                    variant='outlined'
                    margin='dense'
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder='Grade'
                        variant='outlined'
                        margin='dense'
                        name='grade'
                        className={[classes.textField]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} md={2} xs={6}>
                  <Autocomplete
                    className={[classes.autoCompleate]}
                    options={subjectList || []}
                    getOptionLabel={(option) => option.subject?.subject_name}
                    onChange={(e, newValue) => {
                      setFilterDate({
                        ...filterData,
                        subject: newValue,
                      });
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={filterData.subject}
                    size='small'
                    variant='outlined'
                    margin='dense'
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder='Subject'
                        variant='outlined'
                        margin='dense'
                        name='subject'
                        className={[classes.textField]}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      //   handleAllTemplatePagination(null, 1);
                      getAllQuestionPaper(1);
                    }}
                  >
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' spacing={2}>
                {statusFilter &&
                  statusFilter.map((eachStatus, index) => {
                    return (
                      <Grid item key={index}>
                        <Button
                          size='small'
                          variant={
                            filterData.status === eachStatus.id ? 'contained' : 'outlined'
                          }
                          color='primary'
                          onClick={() => {
                            if (filterData.status !== eachStatus.id) {
                              setFilterDate({
                                ...filterData,
                                status: eachStatus.id,
                              });
                              getAllQuestionPaper(1, eachStatus.id);
                            }
                          }}
                        >
                          {eachStatus.name}
                        </Button>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            {/* <Grid item sm={3} md={2} xs={6}></Grid> */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {allQuestionPaper &&
                  allQuestionPaper.length > 0 &&
                  allQuestionPaper.map((eachQuestionPaper, index) => {
                    return (
                      <Grid item xs={12}>
                        <Paper
                          style={{
                            padding: '15px',
                            backgroundColor:
                              eachQuestionPaper?.status === 2 ? '#ffe6e6' : 'white',
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                  <Typography
                                    className='font-size-16'
                                    style={{
                                      color: '#ff1a1a',
                                    }}
                                  >
                                    {eachQuestionPaper.paper_name}
                                  </Typography>
                                  {filterData.status === 0 ? (
                                    <Typography
                                      className='font-size-12'
                                      style={{
                                        color: '#ff1a1a',
                                      }}
                                    >
                                      {eachQuestionPaper?.is_delete
                                        ? 'Deleted'
                                        : eachQuestionPaper?.status === 1
                                        ? 'Draft'
                                        : eachQuestionPaper?.status === 2
                                        ? 'Published'
                                        : eachQuestionPaper?.status === 3
                                        ? 'For Review'
                                        : ''}
                                    </Typography>
                                  ) : null}
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction='row'
                                    justify='center'
                                    alignItems='center'
                                    wrap='nowrap'
                                    spacing={1}
                                  >
                                    <Grid item>
                                      <IconButton
                                        aria-label='more'
                                        aria-controls='long-menu'
                                        aria-haspopup='true'
                                        size='small'
                                        style={{ padding: '0' }}
                                        onClick={(e) => {
                                          handleMenuClick(e, index);
                                        }}
                                      >
                                        <MoreHoriz style={{ height: 18, width: 18 }} />
                                      </IconButton>
                                      {menuOpenIndex === index ? (
                                        <Menu
                                          id='long-menu'
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={open}
                                          onClose={handleMenuClose}
                                          PaperProps={{
                                            style: {
                                              // maxHeight: ITEM_HEIGHT * 4.5,
                                              // width: '20ch',
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              console.log(index, 'indextypo');
                                              handleMenuClose();
                                              history.replace({
                                                pathname:
                                                  '/auto-assessment/question-paper',
                                                state: {
                                                  data: {
                                                    filterData: filterData,
                                                    page:
                                                      alltemplatePageData?.currentPage,
                                                  },
                                                },
                                              });
                                              history.push({
                                                pathname: `/auto-assessment/create-template/`,
                                                state: {
                                                  data: {
                                                    currentStep: 1,
                                                    tempTemplateFrom: JSON.stringify({
                                                      ...eachQuestionPaper,
                                                      access_usr_lvl: accessLabelList?.filter(
                                                        (item) =>
                                                          eachQuestionPaper.access_usr_lvl.includes(
                                                            item.id
                                                          )
                                                      ),
                                                    }),
                                                    tempQuestionHistory: JSON.stringify([
                                                      eachQuestionPaper.section,
                                                    ]),
                                                    type: 'next',
                                                    previousURL: window.location.pathname,
                                                  },
                                                },
                                              });
                                            }}
                                          >
                                            Edit
                                          </MenuItem>
                                          {!eachQuestionPaper.is_delete && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMenuClose();
                                                setDeleteQuestionPaperModalOpen({
                                                  open: true,
                                                  data: eachQuestionPaper.id,
                                                });
                                              }}
                                            >
                                              Delete
                                            </MenuItem>
                                          )}
                                          {eachQuestionPaper.is_delete && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMenuClose();
                                                setRestoreQuestionPaperModalOpen({
                                                  open: true,
                                                  data: eachQuestionPaper.id,
                                                });
                                              }}
                                            >
                                              Restore
                                            </MenuItem>
                                          )}
                                          {!eachQuestionPaper.is_delete &&
                                            eachQuestionPaper.status === 1 && (
                                              <MenuItem
                                                onClick={() => {
                                                  handleMenuClose();
                                                  changeStatusQuestionPaper(
                                                    eachQuestionPaper.id,
                                                    eachQuestionPaper.status,
                                                    3
                                                  );
                                                }}
                                              >
                                                For Review
                                              </MenuItem>
                                            )}
                                          {!eachQuestionPaper.is_delete &&
                                            eachQuestionPaper.status === 3 && (
                                              <MenuItem
                                                onClick={() => {
                                                  handleMenuClose();
                                                  changeStatusQuestionPaper(
                                                    eachQuestionPaper.id,
                                                    eachQuestionPaper.status,
                                                    2
                                                  );
                                                }}
                                              >
                                                Publish
                                              </MenuItem>
                                            )}
                                          <ReactToPrint
                                            // pageStyle={}
                                            onBeforeGetContent={async () => {
                                              await new Promise((resolve) => {
                                                setLoading(true);
                                                setPrintData(eachQuestionPaper);
                                                setTimeout(resolve, 200);
                                                setTimeout(() => handleMenuClose(), 400);
                                              });
                                            }}
                                            onAfterPrint={() => {
                                              // handleSubmitEnquiryModal();
                                              setLoading(false);
                                              setPrintData(null);
                                            }}
                                            trigger={() => <MenuItem>Print</MenuItem>}
                                            content={() => printRef}
                                            documentTitle={`Print`}
                                          />

                                          <ReactToPrint
                                            onBeforeGetContent={async () => {
                                              await new Promise((resolve) => {
                                                setLoading(true);
                                                setPrintAnswer(eachQuestionPaper);
                                                setTimeout(resolve, 200);
                                                setTimeout(() => handleMenuClose(), 400);
                                              });
                                            }}
                                            onAfterPrint={() => {
                                              // handleSubmitEnquiryModal();
                                              setLoading(false);
                                              setPrintAnswer(null);
                                            }}
                                            trigger={() => (
                                              <MenuItem>Answer Sheet</MenuItem>
                                            )}
                                            content={() => amswerRef}
                                            documentTitle={`Print`}
                                          />
                                        </Menu>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={filterData.status === 0 ? '' : 'mt-3'}
                            >
                              <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                  <Typography className='font-size-13'>
                                    Created At:{' '}
                                    {moment(eachQuestionPaper?.created_at).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </Typography>
                                  <Typography className='font-size-13'>
                                    Created By: {eachQuestionPaper?.created_by_name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    style={{
                                      height: '30px',
                                      padding: '0 10px !important',
                                    }}
                                    // onClick={() => checkNextPage(currentStep)}
                                    onClick={() => {
                                      setQuestionPreviewModalOpen({
                                        open: true,
                                        data: eachQuestionPaper,
                                      });
                                    }}
                                  >
                                    {/* {isEdit()?"Edit Template":"Create Template"} */}
                                    View
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                {allQuestionPaper && allQuestionPaper.length === 0 && (
                  <Grid item xs={12}>
                    {/* <div
                      style={{
                        width: '100%',
                        height: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #000',
                        borderRadius: '10px',
                      }}
                    >
                      <Description style={{ opacity: '0.5' }} />
                      <Typography variant='caption'>No Data Found</Typography>
                    </div> */}
                    <div className='periodDataUnavailable mt-4'>
                      <SvgIcon
                        component={() => (
                          <img
                            style={
                              isMobile
                                ? { height: '100px', width: '200px' }
                                : { height: '160px', width: '290px' }
                            }
                            src={unfiltered}
                          />
                        )}
                      />
                      <SvgIcon
                        component={() => (
                          <img
                            style={
                              isMobile
                                ? { height: '20px', width: '250px' }
                                : {
                                    height: '50px',
                                    width: '400px',
                                    marginLeft: '25%',
                                  }
                            }
                            src={noData}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {allQuestionPaper && allQuestionPaper.length ? (
              <Grid item xs={12}>
                <Grid container justify='center'>
                  <Pagination
                    showFirstButton
                    showLastButton
                    size='medium'
                    color='primary'
                    page={alltemplatePageData.currentPage}
                    count={alltemplatePageData.totalPage}
                    onChange={(event, value) => handleAllTemplatePagination(event, value)}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </div>
        {printData && (
          <div>
            <div ref={(el) => (printRef = el)} className={classes.printContent}>
              <QuestionPreview templateFrom={printData} currentStep={4} isPrint={true} />
            </div>
          </div>
        )}
        {printAnswer && (
          <div>
            <div ref={(el) => (amswerRef = el)} className={classes.printContent}>
              <AnswerPreview templateFrom={printAnswer} currentStep={4} isPrint={true} />
            </div>
          </div>
        )}
        {questionPreviewModal}
        {deleteQuestionPaperModal}
        {restoreQuestionPaperModal}
      </Layout>
    </>
  );
};

QuestionPaper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles(styles)(QuestionPaper);

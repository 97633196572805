import React from 'react';
import {
  Divider,
  Grid,
  Radio,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from '../style';
import OrchidsLogo from '../../../../assets/images/orchidsLogo1.png';
import moment from 'moment';
import { getArrayValues } from '../../../../utility-functions';
import endpoints from '../../../../config/endpoints';
import ReactHtmlParser from 'react-html-parser';
import { AttachmentPreviewerContext } from '../../../../components/attachment-previewer/attachment-previewer-contexts';
import { EyeTwoTone, EditOutlined } from '@ant-design/icons';

const AnswerPreview = ({
  classes,
  templateFrom,
  currentStep,
  isPrint,
  isQuestionPaper,
}) => {
  const { openPreview, closePreview } =
    React.useContext(AttachmentPreviewerContext) || {};
  const getS3DomainURL = (fileSrc) => {
    return `${endpoints.s3}${fileSrc}`;
  };

  const handleImageView = (data) => {
    return (
      <div>
        {data?.split('"')?.filter((str) => str.startsWith('https'))?.length > 0 && (
          <a
            onClick={() => {
              openPreview({
                currentAttachmentIndex: 0,
                attachmentsArray: (() => {
                  let newArray = data?.split('"');
                  let filtered = newArray.filter((str) => str.startsWith('https'));
                  const images = filtered || {};
                  const attachmentsArray = [];
                  images.forEach((image) => {
                    const attachmentObj = {
                      src: image,
                      name: `${image}`.split('.').slice(0, -1).join('.'),
                      extension: `.${`${image}`.split('.').slice(-1)[0]}`,
                    };
                    attachmentsArray.push(attachmentObj);
                  });
                  return attachmentsArray;
                })(),
              });
            }}
          >
            <EyeTwoTone />
          </a>
        )}
        {data?.split('"')?.filter((str) => str.startsWith('data:image')).length > 0 &&
          data
            ?.split('"')
            ?.filter((str) => str.startsWith('data:image'))
            ?.map((el, index) => {
              const dataUriParts = el.split(';');
              const mimeTypePart = dataUriParts[0].split(':')[1];
              const mimeTypeToExtension = {
                'image/jpeg': '.jpg',
                'image/png': '.png',
                'image/gif': '.gif',
                'image/jpg': '.jpg',
                // Add more mime types and extensions as needed
              };

              const fileExtension = mimeTypeToExtension[mimeTypePart] || 'unknown';
              return (
                <a
                  onClick={() => {
                    openPreview({
                      currentAttachmentIndex: 0,
                      attachmentsArray: [
                        {
                          src: el,
                          name: el,
                          extension: fileExtension,
                        },
                      ],
                    });
                  }}
                >
                  <EyeTwoTone />
                </a>
              );
            })}
      </div>
    );
  };

  return (
    <div style={{ padding: isPrint === true ? '40px' : '' }}>
      {console.log(templateFrom, 'form')}
      <TableContainer style={{ backgroundColor: '#fff', overflowX: 'hidden' }}>
        <Table size='small'>
          <TableRow className={classes.teblerow}>
            <TableCell
              colspan='2'
              rowspan='3'
              align='center'
              className={[classes.tablecell, classes.tablecellimage]}
            >
              <img src={OrchidsLogo} alt='OrchidsLogo' className={classes.tablelogo} />
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Name</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}></TableCell>
          </TableRow>
          <TableRow className={classes.teblerow}>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Erp</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}></TableCell>
          </TableRow>
          <TableRow className={classes.teblerow}>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Section</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}></TableCell>
          </TableRow>
          {/* <TableRow className={[classes.teblerow, classes.backgroundlightgray]}>
            <TableCell colspan='6' align='center' className={classes.tablecell}>
              <b>
                {typeof templateFrom?.volume === 'object'
                  ? templateFrom?.volume?.map((vol) => vol.volume_name)?.join(', ')
                  : ''}
                {' - '}
                {typeof templateFrom?.chapter === 'object'
                  ? templateFrom?.chapter?.map((each) => each?.chapter_name)?.join(',')
                  : ''}
              </b>
            </TableCell>
          </TableRow> */}
          {/* <TableRow className={classes.teblerow}>
            <TableCell colspan='6' align='center' className={classes.tablecell}>
              <b>weekly test {templateFrom?.academic_year?.session_year}</b>
            </TableCell>
          </TableRow> */}
          <TableRow className={[classes.teblerow, classes.backgroundlightgray]}>
            <TableCell
              colspan='2'
              className={classes.tablecell}
              style={{ paddingRight: 0, textAlign: 'center' }}
            >
              <b>Grade - {templateFrom?.grade?.grade_name}</b>
            </TableCell>
            <TableCell
              colspan='2'
              className={classes.tablecell}
              style={{ textAlign: 'center' }}
            >
              <b>Subject - {templateFrom?.subject?.subject?.subject_name}</b>
            </TableCell>
            <TableCell
              colspan='2'
              className={classes.tablecell}
              style={{ textAlign: 'center' }}
            >
              <b>Marks: {templateFrom?.total_marks}</b>
            </TableCell>
            {!isPrint && !isQuestionPaper ? (
              <TableCell
                colspan='2'
                className={classes.tablecell}
                style={{ textAlign: 'center' }}
              >
                {!isPrint && !isQuestionPaper ? (
                  <b>{`Duration: ${templateFrom?.duration} minutes`}</b>
                ) : null}
              </TableCell>
            ) : null}
            {/* <TableCell colspan='2' className={classes.tablecell}>
              {!isPrint && !isQuestionPaper ? (
                <b>{`Duration: ${templateFrom?.duration} minutes`}</b>
              ) : null}
            </TableCell> */}
          </TableRow>
          {!isPrint && !isQuestionPaper ? (
            <TableRow className={[classes.teblerow, classes.backgroundlightgray]}>
              {/* <TableCell colspan='4' className={classes.tablecell}>
                <b>Test ID: 34565</b>
              </TableCell> */}
              <TableCell colspan='6' className={classes.tablecell}>
                <b>Date: {moment(new Date()).format('DD.MM.YYYY')}</b>
              </TableCell>
            </TableRow>
          ) : null}
          {!isPrint && !isQuestionPaper ? (
            <TableRow className={classes.teblerow}>
              <TableCell colspan='6' className={[classes.tablecell, classes.noBorder]}>
                <strong>General Instruction</strong>
                <Divider />
                <pre className={classes.instruction}>{templateFrom?.instruction}</pre>
                <br />
              </TableCell>
            </TableRow>
          ) : null}
          {currentStep > 1 &&
            templateFrom?.section?.map((eachSection) => {
              return (
                <>
                  <TableRow className={classes.teblerow}>
                    <TableCell
                      colspan='6'
                      className={[classes.tablecell, classes.noBorder]}
                    >
                      <Typography align='center' className={classes.previewSectionHeader}>
                        {eachSection?.header}
                      </Typography>
                      <Typography
                        className={classes.previewSectionDescription}
                        align='justify'
                      >
                        {eachSection?.description}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {eachSection?.question?.map((eachQuestion, index) => {
                    return (
                      <>
                        <TableRow className={classes.teblerow}>
                          <TableCell
                            colspan='6'
                            className={[classes.tablecell, classes.noBorder]}
                            style={{
                              ...(isPrint
                                ? { padding: '15px 0px' }
                                : { padding: '10px 0px' }),
                            }}
                          >
                            <Grid
                              container
                              spacing={1}
                              alignItems='flex-start'
                              // style={{ marginLeft: '1%' }}
                            >
                              <Grid item style={{ width: '5%' }}>
                                <Typography className='font-size-14 font-weight-600'>
                                  {index + 1}.
                                </Typography>
                              </Grid>
                              <Grid item style={{ width: '95%' }}>
                                <div
                                  style={{
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    marginTop: 1,
                                    paddingLeft: '1%',
                                    paddingRight: '1%',
                                    pageBreakInside: 'auto',
                                    pageBreakBefore: 'avoid',
                                    pageBreakAfter: 'avoid',
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: eachQuestion?.question?.question_answer
                                      ?.length
                                      ? eachQuestion?.question?.question_answer[0]
                                          ?.question
                                      : null,
                                  }}
                                  className={classes.generatedQuestionDiv}
                                />
                              </Grid>
                            </Grid>
                            {eachQuestion?.question?.question_answer?.length &&
                            eachQuestion?.question?.question_answer[0]?.options &&
                            eachQuestion?.question?.question_answer[0]?.options
                              ?.length ? (
                              <>
                                {eachQuestion?.question?.question_answer?.length ? (
                                  <>
                                    <Grid
                                      container
                                      spacing={1}
                                      style={{ marginLeft: '4%' }}
                                    >
                                      {eachQuestion?.question?.question_answer[0]?.options?.map(
                                        (eachOption, index) => {
                                          return (
                                            <>
                                              <Grid
                                                container
                                                item
                                                xs={6}
                                                alignItems='center'
                                              >
                                                <Grid item>
                                                  <Typography
                                                    className='font-size-12'
                                                    style={{
                                                      marginRight: '5px',
                                                    }}
                                                  >
                                                    {' '}
                                                    {String.fromCharCode(65 + index)}.
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <Typography className='font-size-12'>
                                                    {eachOption[`option${index + 1}`]
                                                      ?.title
                                                      ? eachOption[`option${index + 1}`]
                                                          ?.title
                                                      : ![undefined, null].includes(
                                                          eachOption[`option${index + 1}`]
                                                            ?.optionValue
                                                        )
                                                      ? typeof eachOption[
                                                          `option${index + 1}`
                                                        ]?.optionValue === 'string'
                                                        ? eachOption[`option${index + 1}`]
                                                            ?.optionValue
                                                        : eachOption[
                                                            `option${index + 1}`
                                                          ]?.optionValue.toString()
                                                      : null}
                                                  </Typography>
                                                  {eachOption[`option${index + 1}`]
                                                    ?.images?.length
                                                    ? eachOption[
                                                        `option${index + 1}`
                                                      ]?.images?.map(
                                                        (eachImage, indexImage) => {
                                                          return (
                                                            <>
                                                              <img
                                                                src={
                                                                  endpoints.s3 + eachImage
                                                                }
                                                                alt='option image'
                                                                height={150}
                                                                style={{
                                                                  padding: '10px',
                                                                }}
                                                                // width=""
                                                              />
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    : null}
                                                </Grid>
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                            {eachQuestion?.question?.question_answer?.length &&
                              eachQuestion?.question?.question_answer[0].answer && (
                                <>
                                  <div
                                    style={{
                                      border: '1px solid #e8e8e8',
                                      borderRadius: '10px',
                                      background: '#e8e8e8',
                                      margin: '5px',
                                      padding: '15px',
                                    }}
                                  >
                                    {eachQuestion?.question?.question_answer?.[0]
                                      ?.answer ? (
                                      <>
                                        {typeof eachQuestion?.question
                                          ?.question_answer?.[0]?.answer == 'object' ? (
                                          <>
                                            <div className='th-16 th-fw-600 th-black'>
                                              {eachQuestion?.question
                                                ?.question_answer?.[0]?.answer?.length > 1
                                                ? 'Answers'
                                                : 'Answer'}
                                            </div>
                                            <div className='mb-3'>
                                              {eachQuestion?.question?.question_answer[0]?.answer?.map(
                                                (obj, i) => {
                                                  let keyName =
                                                    eachQuestion?.question?.question_answer[0]?.options?.filter(
                                                      (el) => obj in el
                                                    ).length > 0
                                                      ? Object.keys(
                                                          eachQuestion?.question?.question_answer[0]?.options?.filter(
                                                            (el) => obj in el
                                                          )[0]
                                                        )[0]
                                                      : '1';
                                                  return (
                                                    <div className='text-justify'>
                                                      {`${String.fromCharCode(
                                                        64 + Number(keyName.slice(-1))
                                                      )}. `}{' '}
                                                      {`${
                                                        eachQuestion?.question?.question_answer[0]?.options?.filter(
                                                          (el) => obj in el
                                                        ).length > 0
                                                          ? eachQuestion?.question?.question_answer[0]?.options?.filter(
                                                              (el) => obj in el
                                                            )[0]?.[obj]?.optionValue
                                                          : obj
                                                      }`}
                                                      {eachQuestion?.question?.question_answer[0]?.options?.filter(
                                                        (el) => obj in el
                                                      ).length > 0 ? (
                                                        <>
                                                          <div className='d-flex'>
                                                            {eachQuestion?.question?.question_answer[0]?.options
                                                              ?.filter(
                                                                (el) => obj in el
                                                              )[0]
                                                              ?.[obj]?.images?.map(
                                                                (el) => {
                                                                  return (
                                                                    <div className='pr-2'>
                                                                      <a
                                                                        onClick={() => {
                                                                          openPreview({
                                                                            currentAttachmentIndex: 0,
                                                                            attachmentsArray: [
                                                                              {
                                                                                src: getS3DomainURL(
                                                                                  el
                                                                                ),
                                                                                name: el,
                                                                                extension:
                                                                                  '.' +
                                                                                  el?.split(
                                                                                    '.'
                                                                                  )[
                                                                                    el?.split(
                                                                                      '.'
                                                                                    )
                                                                                      ?.length -
                                                                                      1
                                                                                  ],
                                                                              },
                                                                            ],
                                                                          });
                                                                        }}
                                                                      >
                                                                        <EyeTwoTone />
                                                                      </a>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              ) || ''}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className='th-16 th-fw-600 th-black'>
                                              Answer
                                            </div>
                                            <div className='mb-3'>
                                              <div className='question-tiny text-justify'>
                                                {ReactHtmlParser(
                                                  eachQuestion?.question
                                                    ?.question_answer[0]?.answer
                                                )}
                                              </div>
                                              {handleImageView(
                                                eachQuestion?.question?.question_answer[0]
                                                  ?.answer
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </>
                              )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </>
              );
            })}
        </Table>
      </TableContainer>
    </div>
  );
};

export default withStyles(styles)(AnswerPreview);

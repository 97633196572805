import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { questiontype } from '../const';
import MyTinyEditor from '../tiny-mce-new';

const Comprehensive = ({
  parentIndex,
  questionTypeProps,
  questionData,
  questionList,
  setQuestions,
}) => {
  const [openEditor, setOpenEditor] = useState(true);
  const [comprehension, setComprehension] = useState('');

  const handleEditorChange = (content, editor) => {
    setComprehension(content);
    let list = [...questionList];
    list[parentIndex].question_answer[0]["question"] = content
    setQuestions(list)
  };

//   const handleContent = () => {
//     let list = [...questionList];
//     if(comptype === 'comp'){
//       let subQuestions = list[parentIndex].subquestions[childIndex]
//       let checkAnswer = subQuestions.question_answer[0]['answer'] ? subQuestions.question_answer[0]['answer'] : ''
//       return checkAnswer
//     } else{
//       return answer
//     }
//   }

  return (
    <>
      <div className='mt-3'>
        {openEditor && (
          <MyTinyEditor
            id={`comprehension${parentIndex}`}
            content={comprehension}
            handleEditorChange={handleEditorChange}
            setOpenEditor={setOpenEditor}
            placeholder='Comprehension...'
          />
        )}
      </div>
    </>
  );
};

export default Comprehensive;

import React, { useState } from 'react';
import { Button, Divider, makeStyles, SvgIcon } from '@material-ui/core';
import ReactPlayer from 'react-player';
import endpoints from '../../../../config/endpoints';
import './styles-style.scss';
import ReactHtmlParser from 'react-html-parser';
import { AttachmentPreviewerContext } from '../../../../components/attachment-previewer/attachment-previewer-contexts';
import VisibilityIcon from '@material-ui/icons/Visibility';


const useStyles = makeStyles((theme) => ({
  questionHeader: {
    // color: theme.palette.secondary.main,
    color: 'orange',
    fontSize: '1.1rem',
    margin: '10px 0',
  },
  answersHeader: {
    // color: theme.palette.secondary.main,
    color: 'orange',
    fontSize: '1.1rem',
    margin: '10px 0',
  },
  questionContainer: {
    // border: '1px solid #dbdbdb',
    // padding: '1rem',
    fontSize: '1.0rem',
    // borderRadius: '10px',
    // margin: '1rem 0',
    // color: `${theme.palette.secondary.main} !important`,
  },
  answersContainer: {
    color: theme.palette.secondary.main,
  },
  option: {
    // backgroundColor: '#f3f3f3',
    // padding: "1rem",
    // margin: '1rem 0',
    // borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const menuOptions = [
  'Assign marks',
  // 'Without marks',
  'Negative marking',
  // 'Grades only',
  // 'Relative marking',
];

const resolveQuestionTypeName = (type) => {
  switch (type) {
    case 1:
      return 'MCQ SINGLE CHOICE';
    case 2:
      return 'MCQ_MULTIPLE_CHOICE';
    case 3:
      return 'Match the Following';
    case 4:
      return 'Video Question';
    case 5:
      return 'PPT Question';
    case 6:
      return 'Matrix Questions';
    case 7:
      return 'Comprehension Questions';
    case 8:
      return 'True False';
    case 9:
      return 'Fill In The Blanks';
    case 10:
      return 'Descriptive';

    default:
      return '--';
  }
};

function extractContent(s) {
  const span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

function extractContentOptionVal(s) {
  if (s?.length > 0 && s.indexOf('<') > -1) {
    // let newarr = s.replace('<', '&lt;');
    let newarr = s.replace(/</g, '&lt;');
    const span = document.createElement('span');
    span.innerHTML = newarr;
    return span.textContent || span.innerText;
  } else {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
}

const extractContentOption = (s) => {
  if (s?.length > 0 && s.indexOf('<') > -1) {
    let newarr = s.replace(/</g, '&lt;');
    newarr = newarr.replace('&lt;p>', '');
    newarr = newarr.replace('&lt;/p>', '');
    // newarr = newarr.replaceAll('&lt;br />',' ');
    newarr = newarr.split('&lt;br />').join(' ');
    const span = document.createElement('span');
    span.innerHTML = newarr;
    return span.textContent || span.innerText;
  } else {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
};

const QuestionView = ({ question, showHeader, index }) => {
  const classes = useStyles();
  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};
  const getS3DomainURL = (fileSrc) => {
    return `${endpoints.s3}${fileSrc}`;
  };
  // const { question_type: questionType } = question;
  const questionType = question?.question_type
    ? question?.question_type
    : question?.question_type_id;
  const [expand, setExpand] = useState(true);
  const toggleExpand = () => {
    setExpand((prev) => !prev);
  };
  return (
    <div className='question-view-container' key={Math.random()}>
      {showHeader && (
        <div className='comprehension-question-header-container'>
          {`${index + 1}. ${resolveQuestionTypeName(
            question.question_type ? question.question_type : question.question_type_id
          )}`}
          <Button variant='contained' color='primary' onClick={toggleExpand}>
            {expand ? 'Close' : 'Expand'}
          </Button>
        </div>
      )}
      {expand && (
        <>
          <div className={classes.questionHeader}>Question {index ? index + 1 : 1}</div>
          {/* <Divider className='secondary-divider' /> */}
          {questionType == 1 && (
            <div className='mcq-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {/* {extractContentOption(question.question_answer[0].question)} */}
                {ReactHtmlParser(question.question_answer[0].question)}
                {/* {  const tempHtml = `
                <p>
                  <strong>Hi</strong>
                </p>
                `; var tempString = renderToString(tempHtml); */}
                {/* {renderToString(question.question_answer[0].question)} */}
                {/* <div
                  style={{ color: 'black', padding: '10px', fontSize: '15px' }}
                  dangerouslySetInnerHTML={{
                    __html: question.question_answer[0].question,
                  }}
                /> */}
              </div>
              <div className={classes.answersContainer}>
                <div className={classes.answersHeader}>Options</div>
                {/* <Divider className='secondary-divider' /> */}
                <div className='options-container'>
                  {question?.question_answer[0]?.options?.map((optionObj, subIndex) => (
                    <div className={classes.option} key={`option-item-${index}`}>
                      <div>
                        {subIndex + 1}.&nbsp;
                        {extractContentOptionVal(
                          optionObj[`option${subIndex + 1}`]?.optionValue
                        )}
                      </div>
                      {`${optionObj[`option${subIndex + 1}`].images}`.length > 0 && (
                        <div>
                          <div style={{ cursor: 'pointer' }}>
                            {/* <Tooltip title='zoom in' arrow> */}
                            <img
                              onClick={() => {
                                openPreview({
                                  currentAttachmentIndex: 0,
                                  attachmentsArray: (() => {
                                    const images =
                                      `${
                                        optionObj[`option${subIndex + 1}`].images
                                      }`.split(',') || {};
                                    const attachmentsArray = [];
                                    images.forEach((image) => {
                                      const attachmentObj = {
                                        src: getS3DomainURL(image),
                                        name: `${image}`
                                          .split('.')
                                          .slice(0, -1)
                                          .join('.'),
                                        extension: `.${
                                          `${image}`.split('.').slice(-1)[0]
                                        }`,
                                      };
                                      attachmentsArray.push(attachmentObj);
                                    });
                                    return attachmentsArray;
                                  })(),
                                });
                              }}
                              src={getS3DomainURL(
                                `${optionObj[`option${subIndex + 1}`].images}`.split(',')
                              )}
                              alt='Click to Zoom'
                              height='100px'
                              width='200px'
                            />
                            {/* `${obj[`option${i + 1}`].images}` */}
                            {/* <ZoomInIcon /> */}
                            {/* </Tooltip> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.answersContainer}>
                <div className={classes.answersHeader}>Answers</div>
                <div className='options-container'>
                  {question?.question_answer[0]?.answer?.map((optionObj, subIndex) => (
                    <div className={classes.option} key={`option-item-${index}`}>
                      {/* {ReactHtmlParser(optionObj[`option${subIndex + 1}`]?.optionValue)} */}
                      {/* {ReactHtmlParser(question.question_answer[0].question)} */}
                      {extractContentOption(optionObj)}

                      {/* {`${optionObj[`option${subIndex + 1}`]?.images}`?.length > 0 && (
                        <div>
                          <a
                            onClick={() => {
                              openPreview({
                                currentAttachmentIndex: 0,
                                attachmentsArray: (() => {
                                  const images =
                                    `${optionObj[`option${subIndex + 1}`]?.images}`.split(',') || {};

                                  const attachmentsArray = [];
                                  images.forEach((image) => {
                                    const attachmentObj = {
                                      src: getS3DomainURL(image),
                                      name: `${image}`.split('.').slice(0, -1).join('.'),
                                      extension: `.${`${image}`.split('.').slice(-1)[0]}`,
                                    };
                                    attachmentsArray.push(attachmentObj);
                                  });
                                  return attachmentsArray;
                                })(),
                              });
                            }}
                          >
                            <SvgIcon component={() => <VisibilityIcon />} />
                          </a>
                        </div>
                      )} */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div>
            {questionType == 2 && (
              // <div className='mcq-container'>
              //   <div
              //     className={`${classes.questionContainer} question-tiny text-justify`}
              //   >
              //     {/* {extractContentOption(question.question_answer[0].question)} */}
              //     {ReactHtmlParser(question.question_answer[0].question)}
              //   </div>
              //   <div className='answers-container'>
              //     <div className={classes.answersHeader}>Options</div>
              //     {/* <Divider className='secondary-divider' /> */}
              //     <div className='options-container'>
              //       {question.question_answer[0]?.options.map((optionObj, subIndex) => (
              //         <div className={classes.option} key={`option-item-${index}`}>
              //           {/* {console.log({ optionObj }, 'mk')} */}
              //           <div>
              //             Option {subIndex + 1}:&nbsp;
              //             {extractContent(optionObj[`option${subIndex + 1}`].optionValue)}
              //           </div>

              //           {`${optionObj[`option${subIndex + 1}`].images}`.length > 0 && (
              //             <div>
              //               <div style={{ cursor: 'pointer' }}>
              //                 {/* <Tooltip title='zoom in' arrow> */}
              //                 {Array.isArray(optionObj[`option${subIndex + 1}`].images) &&
              //                   optionObj[`option${subIndex + 1}`].images.map(
              //                     (imgitem, imgindex) => (
              //                       <img
              //                         onClick={() => {
              //                           openPreview({
              //                             currentAttachmentIndex: imgindex,
              //                             attachmentsArray: (() => {
              //                               const images =
              //                                 `${
              //                                   optionObj[`option${subIndex + 1}`].images
              //                                 }`.split(',') || {};
              //                               const attachmentsArray = [];
              //                               images.forEach((image) => {
              //                                 const attachmentObj = {
              //                                   src: getS3DomainURL(image),
              //                                   name: `${image}`
              //                                     .split('.')
              //                                     .slice(0, -1)
              //                                     .join('.'),
              //                                   extension: `.${
              //                                     `${image}`.split('.').slice(-1)[0]
              //                                   }`,
              //                                 };
              //                                 // console.log('attachmentObj', attachmentObj)
              //                                 attachmentsArray.push(attachmentObj);
              //                               });

              //                               return attachmentsArray;
              //                             })(),
              //                           });
              //                         }}
              //                         // src={getS3DomainURL(
              //                         //   `${optionObj[`option${subIndex + 1}`].images}`.split(
              //                         //     ','
              //                         //   )
              //                         // )}
              //                         src={getS3DomainURL(imgitem)}
              //                         alt={getS3DomainURL(
              //                           `${optionObj[`option${subIndex + 1}`].images}`
              //                         )}
              //                         height='50px'
              //                         width='75px'
              //                       />
              //                     )
              //                   )}
              //                 {/* `${obj[`option${i + 1}`].images}` */}
              //                 {/* <ZoomInIcon /> */}
              //                 {/* </Tooltip> */}
              //               </div>
              //             </div>
              //           )}
              //         </div>
              //       ))}
              //     </div>
              //   </div>
              //   <div className='answers-container'>
              //     <div className={classes.answersHeader}>Answers</div>
              //     {/* <Divider className='secondary-divider' /> */}
              //     <div className='options-container'>
              //       {question?.question_answer[0]?.answer?.map((optionObj, subIndex) => (
              //         <div className={classes.option} key={`option-item-${index}`}>
              //           {ReactHtmlParser(optionObj)}
              //           {/* {`${optionObj[`option${subIndex + 1}`]?.images}`?.length > 0 && (
              //             <div>
              //               <a
              //                 onClick={() => {
              //                   openPreview({
              //                     currentAttachmentIndex: 0,
              //                     attachmentsArray: (() => {
              //                       const images =
              //                         `${optionObj[`option${subIndex + 1}`]?.images}`.split(',') || {};

              //                       const attachmentsArray = [];
              //                       images.forEach((image) => {
              //                         const attachmentObj = {
              //                           src: getS3DomainURL(image),
              //                           name: `${image}`.split('.').slice(0, -1).join('.'),
              //                           extension: `.${`${image}`.split('.').slice(-1)[0]}`,
              //                         };
              //                         attachmentsArray.push(attachmentObj);
              //                       });
              //                       return attachmentsArray;
              //                     })(),
              //                   });
              //                 }}
              //               >
              //                 <SvgIcon component={() => <VisibilityIcon />} />
              //               </a>
              //             </div>
              //           )} */}
              //         </div>
              //       ))}
              //     </div>
              //     <Divider className='secondary-divider' />
              //   </div>
              // </div>
              <div className='mcq-container'>
                 <div className='question-container'>
                   <div>
                     {question.question_type === 1
                       ? 'MCQ SINGLE'
                       : 'MCQ MULTI'}
                   </div>
                   <div style={{ color: '#014B7E' }}>
                     <span style={{ color: 'red', fontSize: 16 }}>
                       {`Q${index + 1}`}:{' '}
                     </span>{' '}
                     {ReactHtmlParser(
                       question.question_answer[0].question
                     )}
                   </div>
                 </div>
                 <div className='resourceBulkDownload'>Answers</div>
                 <div className='question-container'>
                   {question?.question_answer[0]?.answer.map(
                     (obj, i) => (
                       <div>{obj}</div>
                     )
                   )}
                 </div>
                 <div className='resourceBulkDownload'>Options</div>
                 <div>
                   {question?.question_answer[0]?.options.map(
                     (obj, i) => (
                       <div className='question-container'>
                         {`OPTION${i + 1}:   ${
                           obj[`option${i + 1}`].optionValue
                         }`}
                         {`${obj[`option${i + 1}`].images}`.length > 0 && (
                           <div>
                             <a
                               onClick={() => {
                                 openPreview({
                                   currentAttachmentIndex: 0,
                                   attachmentsArray: (() => {
                                     const images =
                                       `${obj[`option${i + 1}`].images}`.split(
                                         ','
                                       ) || {};
                                     const attachmentsArray = [];
                                     images.forEach((image) => {
                                       const attachmentObj = {
                                         src: getS3DomainURL(image),
                                         name: `${image}`
                                           .split('.')
                                           .slice(0, -1)
                                           .join('.'),
                                         extension: `.${
                                           `${image}`.split('.').slice(-1)[0]
                                         }`,
                                       };
                                       attachmentsArray.push(attachmentObj);
                                     });
                                     return attachmentsArray;
                                   })(),
                                 });
                               }}
                             >
                               <SvgIcon component={() => <VisibilityIcon />} />
                             </a>
                           </div>
                         )}
                       </div>
                     )
                   )}
                 </div>
               </div>
            )}
          </div>

          {questionType === 3 && (
            <div className='match-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {ReactHtmlParser(question.question_answer[0].question)}
              </div>
              <div className='answers-container'>
                <div className={classes.answersHeader}>Answers Question</div>
                {/* <Divider className='secondary-divider' /> */}
                <div className='options-container'>
                  {question?.question_answer[0]?.questionAnswer.map((obj, index) => (
                    <div className='option'>
                      {obj?.answer}&nbsp;&nbsp;{obj?.question}
                    </div>
                  ))}
                </div>
                <div className='match-info'>OPTIONS</div>
                {/* <Divider className='secondary-divider' /> */}
                <div className='options-container'>
                  {question?.question_answer[0]?.options.map((obj, index) => (
                    <div className='option'>{obj?.optionValue}</div>
                  ))}
                </div>
                <div className='match-info'>MATCHING OPTIONS</div>
                {/* <Divider className='secondary-divider' /> */}
                <div className='options-container'>
                  {question?.question_answer[0]?.matchingOptions.map((obj, index) => (
                    <div className='option'>{obj?.optionValue}</div>
                  ))}
                </div>
                <Divider className='secondary-divider' />
              </div>
            </div>
          )}
          {questionType === 6 && (
            <div className='matrix-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {ReactHtmlParser(question.question_answer[0].question)}
              </div>
              <div className='answers-container'>
                <div className={classes.answersHeader}>Answers</div>
                <div className='matrix-columns'>
                  {question.question_answer[0]?.options.map((obj) => (
                    <div className='matrix-column'>
                      <span>{obj.optionValue}</span>
                      <span className='vertical-divider'></span>
                    </div>
                  ))}
                </div>

                <div className='options-container'>
                  {question.question_answer[0]?.matrixOptions.map((opt, index) => (
                    <div className='option'>{opt[`optionValue`]} </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {questionType === 8 && (
            <div className='true-false-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {ReactHtmlParser(question.question_answer[0]?.question)}
              </div>
              <div className='answers-container'>
                <div className={classes.answersHeader}>Options</div>

                <div className='options-container '>
                  {question.question_answer[0]?.options.map((obj, i) => (
                    <div className='flex-space-between'>
                      {' '}
                      {/* <div className='matrix-column'>
                        <div>{extractContentOption(Object.keys(obj)[0] || '')}</div>
                      </div> */}
                      <div className='matrix-column d-flex'>
                        <div>{Object.keys(obj)[0] || ''}</div> {' : '}&nbsp;
                        {/* <div>{obj[Object.keys(obj)[0]].optionValue}</div> */}
                        <div>
                          {obj[Object.keys(obj)[0]].optionValue === true ||
                          obj[Object.keys(obj)[0]].optionValue === 'True'
                            ? 'True'
                            : 'False'}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.answersHeader}>Answers</div>
                <div className='options-container '>
                  <div className={`${classes.option} flex-space-between`}>
                    {question.question_answer[0]?.answer.map((obj) => (
                      <div className='matrix-column'>
                        <span>{obj}</span>
                        {/* <span className='vertical-divider'></span> */}
                      </div>
                    ))}{' '}
                  </div>
                </div>
              </div>
            </div>
          )}
          {questionType === 9 && (
            // <div className='fill-in-the-blanks-container'>
            //   <div className={`${classes.questionContainer} question-tiny text-justify`}>
            //     {ReactHtmlParser(question.question_answer[0]?.question)}
            //   </div>
            //   <div className='answers-container'>
            //     <div className={classes.answersHeader}>Answers</div>
            //     {/* <Divider className='secondary-divider' /> */}
            //     <div className='options-container'>
            //       {question.question_answer[0]?.options.map((optionObj, subIndex) => (
            //         <div className={classes.option} key={`option-item-${index}`}>
            //           {/* {console.log({ optionObj }, 'mk')} */}
            //           <div>
            //             {subIndex + 1}.&nbsp;
            //             {extractContent(optionObj[`option${subIndex + 1}`].optionValue)}
            //           </div>

            //           {`${optionObj[`option${subIndex + 1}`].images}`.length > 0 && (
            //             <div>
            //               <div style={{ cursor: 'pointer' }}>
            //                 {/* <Tooltip title='zoom in' arrow> */}
            //                 {Array.isArray(optionObj[`option${subIndex + 1}`].images) &&
            //                   optionObj[`option${subIndex + 1}`].images.map(
            //                     (imgitem, imgindex) => (
            //                       <img
            //                         onClick={() => {
            //                           openPreview({
            //                             currentAttachmentIndex: imgindex,
            //                             attachmentsArray: (() => {
            //                               const images =
            //                                 `${
            //                                   optionObj[`option${subIndex + 1}`].images
            //                                 }`.split(',') || {};
            //                               const attachmentsArray = [];
            //                               images.forEach((image) => {
            //                                 const attachmentObj = {
            //                                   src: getS3DomainURL(image),
            //                                   name: `${image}`
            //                                     .split('.')
            //                                     .slice(0, -1)
            //                                     .join('.'),
            //                                   extension: `.${
            //                                     `${image}`.split('.').slice(-1)[0]
            //                                   }`,
            //                                 };
            //                                 // console.log('attachmentObj', attachmentObj)
            //                                 attachmentsArray.push(attachmentObj);
            //                               });

            //                               return attachmentsArray;
            //                             })(),
            //                           });
            //                         }}
            //                         // src={getS3DomainURL(
            //                         //   `${optionObj[`option${subIndex + 1}`].images}`.split(
            //                         //     ','
            //                         //   )
            //                         // )}
            //                         src={getS3DomainURL(imgitem)}
            //                         alt={getS3DomainURL(
            //                           `${optionObj[`option${subIndex + 1}`].images}`
            //                         )}
            //                         height='50px'
            //                         width='75px'
            //                       />
            //                     )
            //                   )}
            //                 {/* `${obj[`option${i + 1}`].images}` */}
            //                 {/* <ZoomInIcon /> */}
            //                 {/* </Tooltip> */}
            //               </div>
            //             </div>
            //           )}
            //         </div>
            //       ))}
            //     </div>
            //   </div>
            //   <div className='answers-container'>
            //     {/* <div className={classes.answersHeader}>Answers</div>
            //     <div className='options-container'>
            //       {question?.question_answer[0]?.answer?.map((obj) => (
            //         <div className='matrix-column'>
            //           <span>{obj}</span>
            //         </div>
            //       ))}
            //     </div>  */}
            //     {/* <div className='answers-header '>Options</div>
            //     <Divider className='secondary-divider' />
            //     <div className='options-container'>
            //       {question.question_answer[0]?.options?.map((obj, i) => (
            //         <div className='matrix-column'>
            //           {extractContentOption(obj[`option${i + 1}`].optionValue)}
            //         </div>
            //       ))}
            //     </div> */}
            //   </div>
            //   <Divider className='secondary-divider' />
            // </div>
            <div className='fill-in-the-blanks-container'>
            <div className='question-container'>
              {ReactHtmlParser(question.question_answer[0]?.question)}
            </div>
            <div className='answers-container'>
              <div className={classes.answersHeader}>Answers</div>
              {/* <Divider className='secondary-divider' /> */}
              <div className='options-container'>
                {question.question_answer[0]?.answer?.map((obj) => (
                  <div className='matrix-column'>
                    <span>{obj}</span>
                  </div>
                ))}
              </div>
              {/* <div className={classes.answersHeader}>Options</div> */}
              {/* <Divider className='secondary-divider' /> */}
              {/* <div className='options-container'>
                {question.question_answer[0]?.options?.map((obj, i) => (
                  <div className='matrix-column'>
                    {obj[`option${i + 1}`].optionValue}
                  </div>
                ))}
              </div> */}
            </div>
            <Divider className='secondary-divider' />
          </div>
          )}
          {questionType === 7 && (
            <div className='comprehension-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {/* <span style={{ color: 'red', fontSize: 16 }}>{`Q${indexQue + 1}`}: </span> */}
                {ReactHtmlParser(question.question_answer[0]?.question)}
              </div>
              <div className='sub-questions-container'>
                {question.sub_questions?.map((question, index) => (
                  <div
                    className={`sub-question-container ${question.id}`}
                    key={question.id}
                  >
                    <QuestionView question={question} index={index} showHeader />
                  </div>
                ))}
              </div>
            </div>
          )}
          {questionType === 10 && (
            <div className='descriptive-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {ReactHtmlParser(question.question_answer[0]?.question)}
              </div>
              <div className='answers-container'>
                <div className={classes.answersHeader}>Answers</div>
                <div className='options-container'>
                  <div className='options'>
                    {extractContent(question.question_answer[0]?.answer)}
                  </div>
                </div>
              </div>
              <Divider className='secondary-divider' />
            </div>
          )}
          {questionType === 4 && (
            <div className='video-container'>
              <div className={`${classes.questionContainer} question-tiny text-justify`}>
                {ReactHtmlParser(question?.question_answer[0]?.question)}
                <ReactPlayer
                  playing={false}
                  controls={true}
                  url={`${endpoints.s3}${question?.question_answer[0]?.video}`}
                  style={{ maxWidth: '100%' }}
                />
              </div>
              <div className='sub-questions-container'>
                {question.sub_questions?.map((question, index) => (
                  <div className='sub-question-container' key={question.id}>
                    <QuestionView question={question} index={index} showHeader />
                  </div>
                ))}
              </div>
              {/* <div className='answers-container'>
                <div className='answers-header '>Answers</div>
                <Divider className='secondary-divider' />
                <div className='options-container'>
                  <div className='option'>Answer</div>
                </div>
              </div> */}
              <Divider className='secondary-divider' />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionView;

import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
  Box,
  IconButton,
  Divider,
  ButtonGroup,
  Icon,
  FormControl,
  Select,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import styles from './createTemplate.style';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  LoopOutlined,
  RemoveCircleOutline,
  Send,
  ShuffleSharp,
  BorderColorSharp,
  Visibility,
} from '@material-ui/icons';
// import "../../cssStyles/page3.css";
// import { Modal } from "../../../../utility/ui";
// import useWindowDimensions from "../../../../utility/customHooks/useWindowDimensions";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Modal } from "@mui/material";
import Modal from '../../../../components/commonModal';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';

const CreateSection = ({
  classes,
  dataList,
  templateFrom,
  setTemplateFrom,
  goToStep,
  onChange,
  currentStep,
  checkNextPage,
  totalMarks,
  questionHistory,
  regenerate,
  changeQuestionGenerate,
  handlePaperForward,
  handlePaperBackward,
  handleExportOptions,
  handleIncrement,
  handleDecrement,
  history,
  isPreviousPageTemplate,
}) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [eachQuestionPreview, setEachQuestionPreview] = useState({
    open: false,
    data: null,
  });
  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState({
    open: false,
    sectionIndex: null,
    questionIndex: null,
  });

  // const { width } = useWindowDimensions();
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('md'));

  console.log(dataList, 'dataList');

  const isEdit = () => {
    return templateFrom.hasOwnProperty('id');
  };

  let eachQuestionPreviewModal = null;
  if (eachQuestionPreview.open) {
    eachQuestionPreviewModal = (
      <Modal
        open={eachQuestionPreview.open}
        // click={() =>
        //   setEachQuestionPreview({
        //     ...eachQuestionPreview,
        //     data: null,
        //     open: false,
        //   })
        // }
        large
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <>
          <div>
            <div
              style={{
                backgroundColor: 'rgb(31 124 237)',
                padding: '5px 15px',
              }}
            >
              <Grid container spacing={1} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography
                    className='font-size-16 font-weight-600'
                    style={{ color: 'white' }}
                  >
                    Preview Question
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    style={{ color: 'white' }}
                    onClick={() =>
                      setEachQuestionPreview({
                        ...eachQuestionPreview,
                        data: null,
                        open: false,
                      })
                    }
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: '15px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: eachQuestionPreview?.data?.question?.question_answer?.length
                    ? eachQuestionPreview?.data?.question?.question_answer[0]?.question
                    : null,
                }}
                style={{ marginLeft: '0%', fontSize: '13px' }}
                className={classes.generatedQuestionDiv}
              />
              {eachQuestionPreview?.data?.question?.question_answer[0]?.options
                ?.length ? (
                <Typography
                  className='font-size-12 font-weight-600'
                  style={{ marginTop: '15px' }}
                >
                  Options:
                </Typography>
              ) : null}
              {eachQuestionPreview?.data?.question?.question_answer[0]?.options
                ?.length ? (
                <>
                  {eachQuestionPreview?.data?.question?.question_answer?.length ? (
                    <>
                      <Grid container spacing={1}>
                        {eachQuestionPreview?.data?.question?.question_answer[0]?.options?.map(
                          (eachOption, index) => {
                            return (
                              <>
                                <Grid container item md={6} sm={12} alignItems='center'>
                                  <Grid item>
                                    <Typography
                                      className='font-size-12'
                                      style={{
                                        marginRight: '5px',
                                      }}
                                    >
                                      {' '}
                                      {String.fromCharCode(65 + index)}.
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography className='font-size-12'>
                                      {eachOption[`option${index + 1}`]?.title
                                        ? eachOption[`option${index + 1}`]?.title
                                        : ![undefined, null]?.includes(
                                            eachOption[`option${index + 1}`]?.optionValue
                                          )
                                        ? typeof eachOption[`option${index + 1}`]
                                            ?.optionValue === 'string'
                                          ? eachOption[`option${index + 1}`]?.optionValue
                                          : eachOption[
                                              `option${index + 1}`
                                            ]?.optionValue.toString()
                                        : null}
                                    </Typography>
                                    {eachOption[`option${index + 1}`]?.images?.length
                                      ? eachOption[`option${index + 1}`]?.images?.map(
                                          (eachImage, indexImage) => {
                                            return (
                                              <>
                                                <img
                                                  src={endpoints.s3 + eachImage}
                                                  alt='option image'
                                                />
                                              </>
                                            );
                                          }
                                        )
                                      : null}
                                  </Grid>
                                </Grid>
                              </>
                            );
                          }
                        )}
                      </Grid>
                    </>
                  ) : null}
                </>
              ) : null}
              <Typography
                className='font-size-12 font-weight-600'
                style={{ marginTop: '15px' }}
              >
                Answer:
              </Typography>
              {typeof eachQuestionPreview?.data?.question?.question_answer[0]?.answer ===
              'string' ? (
                <>
                  <div
                    style={{ fontSize: '13px' }}
                    dangerouslySetInnerHTML={{
                      __html: eachQuestionPreview?.data?.question?.question_answer?.length
                        ? eachQuestionPreview?.data?.question?.question_answer[0]?.answer
                        : null,
                    }}
                    className={classes.generatedQuestionDiv}
                  />
                </>
              ) : (
                <>
                  {eachQuestionPreview?.data?.question?.question_answer[0]?.answer?.map(
                    (eachAns, ansIndex) => {
                      return (
                        <>
                          {eachQuestionPreview?.data?.question?.question_answer[0]?.options?.map(
                            (eachOption, optionIndex) => {
                              return (
                                <>
                                  {eachOption.hasOwnProperty(eachAns) ? (
                                    <Grid
                                      container
                                      item
                                      // xs={6}
                                      alignItems='center'
                                    >
                                      <Grid item>
                                        <Typography
                                          className='font-size-12'
                                          style={{
                                            marginRight: '5px',
                                          }}
                                        >
                                          {' '}
                                          {String.fromCharCode(65 + optionIndex)}.
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className='font-size-12'>
                                          {eachOption[`option${optionIndex + 1}`]?.title
                                            ? eachOption[`option${optionIndex + 1}`]
                                                ?.title
                                            : eachOption[`option${optionIndex + 1}`]
                                                ?.optionValue
                                            ? eachOption[`option${optionIndex + 1}`]
                                                ?.optionValue
                                            : null}
                                        </Typography>
                                        {eachOption[`option${optionIndex + 1}`]?.images
                                          ?.length
                                          ? eachOption[
                                              `option${optionIndex + 1}`
                                            ]?.images?.map((eachImage, indexImage) => {
                                              return (
                                                <>
                                                  <img
                                                    src={endpoints.s3 + eachImage}
                                                    alt='option image'
                                                  />
                                                </>
                                              );
                                            })
                                          : null}
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    }
                  )}
                </>
              )}

              <Grid
                container
                spacing={2}
                justify='space-between'
                style={{
                  marginTop: '40px',
                  textAlign: 'center',
                  fontSize: '13px',
                }}
              >
                <Grid item md={2} sm={4} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#C41D7F',
                      backgroundColor: '#FFF0F6',
                      border: '1px solid #FFADD2',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.bloom?.category_name}</span>
                  </div>
                </Grid>
                <Grid item md={2} sm={4} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#D46B08',
                      backgroundColor: '#FFF7E6',
                      border: '1px solid #FFD591',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.difficult?.level_name}</span>
                  </div>
                </Grid>
                <Grid item md={2} sm={4} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#0958D9',
                      backgroundColor: '#E6F4FF',
                      border: '1px solid #91CAFF',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.type?.type_name}</span>
                  </div>
                </Grid>
                <Grid item md={4} sm={9} xs={8}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#389E0D',
                      backgroundColor: '#F6FFED',
                      border: '1px solid #B7EB8F',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.subType?.type_name}</span>
                  </div>
                </Grid>
                <Grid item md={2} sm={3} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#08979C',
                      backgroundColor: '#E6FFFB',
                      border: '1px solid #87E8DE',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>
                      {eachQuestionPreview?.data?.marks}{' '}
                      {parseInt(eachQuestionPreview?.data?.marks) > 1 ? 'marks' : 'mark'}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      </Modal>
    );
  }

  let deleteQuestionModal = null;
  if (deleteQuestionModalOpen?.open) {
    deleteQuestionModal = (
      <Modal
        open={deleteQuestionModalOpen?.open}
        click={() =>
          setDeleteQuestionModalOpen({
            open: false,
            sectionIndex: null,
            questionIndex: null,
          })
        }
        small={!isMobile && 768}
        medium={isMobile && 768}
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <div style={{ padding: '15px' }}>
          <Typography variant='body1' style={{ margin: '0 0 15px' }}>
            Are you sure to delete this question?
          </Typography>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                style={{ backgroundColor: 'transparent' }}
                onClick={() =>
                  setDeleteQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                  })
                }
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  handleDecrement(
                    deleteQuestionModalOpen?.sectionIndex,
                    deleteQuestionModalOpen?.questionIndex
                  );
                  setDeleteQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                  });
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
  return (
    <>
      <Grid item container justify='space-between'>
        <Grid item container style={{ width: 'fit-content' }}>
          {currentStep === 3 || !templateFrom?.questionGenerate ? (
            <>
              <strong className='font-size-14'>Create Question Paper</strong>
            </>
          ) : (
            <Grid
              item
              // lg={5}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton
                size='small'
                disabled={templateFrom?.currentPaper === 1}
                onClick={handlePaperBackward}
              >
                <ArrowBackIos fontSize='small' />
              </IconButton>
              <Typography className='font-size-14'>
                {templateFrom?.currentPaper} / {questionHistory?.length}
              </Typography>
              <IconButton
                size='small'
                disabled={templateFrom?.currentPaper === questionHistory?.length}
                onClick={handlePaperForward}
              >
                <ArrowForwardIos fontSize='small' />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Typography
            className='font-size-12'
            style={{ fontWeight: 600, alignSelf: 'flex-end' }}
          >
            {'Total Marks Added: ' + totalMarks + ' / ' + templateFrom?.total_marks}
          </Typography>
        </Grid>
      </Grid>
      {history.location?.state?.data?.previousURL ===
        '/auto-assessment/question-paper' && (
        <Grid
          item
          style={{
            padding: '1%',
            backgroundColor: '#F6F6F6',
            boxShadow: '0 0px 4px rgba(0, 0, 0, 0.25)',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Grid
            container
            style={{
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <Grid item style={{ marginRight: '1%' }}>
              <IconButton onClick={() => goToStep(1)} style={{ padding: 0 }}>
                <BorderColorSharp color='black' style={{ width: 15, height: 15 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className='font-size-12'>
                {'Title:  ' + templateFrom?.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              alignItems: 'center',
              // spacing: 21,
            }}
          >
            <Grid item lg={1} md={2} sm={2} xs={2}>
              <Typography className='font-size-12'>
                {templateFrom?.grade?.grade_name}
              </Typography>
            </Grid>
            <Grid item lg={1} md={2} sm={2} xs={2} className='subject'>
              <Typography className='font-size-12'>
                {templateFrom?.subject?.subject?.subject_name}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={7} className='chapter'>
              <Typography className='font-size-12'>
                {typeof templateFrom?.volume === 'object' ? (
                  templateFrom?.volume.length > 2 ? (
                    <Tooltip
                      title={templateFrom?.volume
                        .map((each) => each?.volume_name)
                        .join(', ')}
                      placement='top'
                    >
                      <span>
                        {templateFrom?.volume
                          .slice(0, 2)
                          .map((each) => each?.volume_name)
                          .join(', ')}{' '}
                        <span style={{ color: 'grey' }}>
                          +{templateFrom?.volume.length - 2} more
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    templateFrom?.volume.map((each) => each?.volume_name).join(', ')
                  )
                ) : (
                  ''
                )}
                {' - '}
                {typeof templateFrom?.chapter === 'object' ? (
                  templateFrom?.chapter.length > 2 ? (
                    <Tooltip
                      title={templateFrom?.chapter
                        .map((each) => each?.chapter_name)
                        .join(', ')}
                      placement='top'
                    >
                      <span>
                        {templateFrom?.chapter
                          .slice(0, 2)
                          .map((each) => each?.chapter_name)
                          .join(', ')}{' '}
                        <span style={{ color: 'grey' }}>
                          +{templateFrom.chapter.length - 2} more
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    templateFrom?.chapter.map((each) => each?.chapter_name).join(', ')
                  )
                ) : (
                  ''
                )}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={3}
              xs={4}
              className='duration'
              style={{ marginLeft: '2%' }}
            >
              <Typography className='font-size-12'>
                {'Total Duration: ' + templateFrom?.duration}
              </Typography>
            </Grid>
            <Grid item lg={2} md={3} sm={3} xs={4} className='total_marks'>
              <Typography className='font-size-12'>
                {'Total Marks: ' + templateFrom?.total_marks}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                color='primary'
                size='small'
                variant='contained'
                onClick={() => goToStep(1)}
              >
                Edit
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      )}

      {templateFrom?.section?.map((sec, i) => {
        const sectionTotalMarks = sec.question.reduce(
          (total, question) => total + parseFloat(question.marks || 0),
          0
        );

        return (
          <div className='mt-3'>
            <Grid
              item
              key={i}
              container
              style={{
                padding: '1%',
                border: '1.5px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid item container style={{ alignItems: 'center' }}>
                <Grid item style={{ marginRight: '2%' }}>
                  <IconButton onClick={() => goToStep(2)} style={{ padding: 0 }}>
                    <BorderColorSharp color='black' style={{ width: 18, height: 18 }} />
                  </IconButton>
                </Grid>
                <Grid item lg={1} md={2} sm={1} xs={2}>
                  {sec?.header.length > 10 ? (
                    <Tooltip title={sec?.header} placement='top'>
                      <Typography className='font-size-12'>
                        {sec?.header
                          ? sec?.header.slice(0, 10) + '...'
                          : 'Section ' + (i + 1)}
                        {console.log(sec?.header.length, 'sech')}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography className='font-size-12'>
                      {sec?.header ? sec?.header : 'Section ' + (i + 1)}
                      {console.log(sec?.header.length, 'sech')}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={8}
                  sm={6}
                  xs={8}
                  style={{ backgroundColor: '#F5F5F5', padding: 3 }}
                >
                  <Typography className='font-size-12' style={{ marginLeft: 2 }}>
                    {sec?.description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={3}
                  sm={2}
                  xs={4}
                  style={{
                    marginLeft: '1%',
                    display: 'flex',
                    justify: 'center',
                  }}
                  className={classes.totalQuestion}
                >
                  <Typography className='font-size-12'>
                    Total Question: {sec.totalQuestion}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={2}
                  style={{
                    display: 'flex',
                    justify: 'center',
                  }}
                >
                  <Typography className='font-size-12'>{`Added Marks: ${sectionTotalMarks}`}</Typography>
                </Grid>
              </Grid>
              <Divider style={{ width: '100%', marginTop: '1%' }} />
              <br />

              {sec?.question?.map((val, index) => (
                <Grid
                  item
                  key={index}
                  container
                  spacing={1}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 5,
                    flexWrap: 'nowrap',
                  }}
                >
                  <Grid item>
                    <Typography
                      className='font-size-12'
                      style={{ whiteSpace: 'nowrap', minWidth: '30px' }}
                    >{`Q. ${index + 1}`}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      padding: '0',
                      width: '100%',
                      border: '1.5px solid rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justify: 'space-between',
                    }}
                  >
                    {val?.question && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          fontSize: 13,
                          backgroundColor: '#F4F5F9',
                          padding: '0.7%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        className={classes.generatedQuestion}
                        onClick={() => {
                          if (typeof val?.question !== 'string') {
                            setEachQuestionPreview({
                              ...eachQuestionPreview,
                              data: val,
                              open: true,
                            });
                          } else {
                            setAlert('error', 'Question not present.');
                          }
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: val?.question?.question_answer?.length
                              ? val?.question?.question_answer[0]?.question
                              : val?.question
                              ? val?.question
                              : null,
                          }}
                          style={{ marginLeft: '0%' }}
                          // className={[classes.generatedQuestionDiv,typeof(val?.question)==="string"?classes.noDataQuestion:null]}
                          className={`${classes.generatedQuestionDiv} ${
                            typeof val?.question === 'string'
                              ? classes.noDataQuestion
                              : ''
                          }`}
                        />
                        <span style={{ marginLeft: '1%' }}>
                          <Visibility style={{ fontSize: 18, color: 'grey' }} />
                        </span>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justify: 'space-evenly',
                        padding: '0% 0% 1% 1%',
                      }}
                    >
                      <Grid item lg={4} md={3} sm={5} xs={2}>
                        <Autocomplete
                          className={[classes.autoCompleate]}
                          options={dataList?.bloomList || []}
                          getOptionLabel={(option) => option.category_name}
                          onChange={(e, newValue) => {
                            onChange('bloom', i, index, newValue);
                          }}
                          getOptionSelected={(option, value) => option?.id === value?.id}
                          value={templateFrom?.section[i]?.question[index]?.bloom || {}}
                          size='small'
                          variant='outlined'
                          margin='dense'
                          disabled={
                            templateFrom?.questionGenerate ||
                            templateFrom?.selectTemplate === 2
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              style={{
                                width: '95%',
                              }}
                              className={[classes.bloomsField, classes.textField]}
                              sx={{ input: { color: 'red' } }}
                              placeholder='Blooms'
                              variant='outlined'
                              margin='dense'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item lg={4} md={3} sm={5} xs={2}>
                        <Autocomplete
                          className={[classes.autoCompleate]}
                          options={
                            isPreviousPageTemplate()
                              ? dataList?.difficultyList
                              : dataList?.difficultyList?.filter((each) =>
                                  each.blooms?.includes(
                                    templateFrom?.section[i]?.question[index]?.bloom?.id
                                  )
                                ) || []
                          }
                          getOptionLabel={(option) => option.level_name}
                          onChange={(e, newValue) => {
                            onChange('difficult', i, index, newValue);
                          }}
                          getOptionSelected={(option, value) => option?.id === value?.id}
                          value={
                            templateFrom?.section[i]?.question[index]?.difficult || {}
                          }
                          size='small'
                          variant='outlined'
                          margin='dense'
                          disabled={
                            templateFrom?.questionGenerate ||
                            templateFrom?.selectTemplate === 2
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              style={{
                                width: '95%',
                                // backgroundColor: "#FADBFF",
                              }}
                              className={[classes.difficultyField, classes.textField]}
                              placeholder='Difficulty'
                              variant='outlined'
                              margin='dense'
                              // value={val.difficult || ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item lg={4} md={3} sm={5} xs={2}>
                        <Autocomplete
                          className={[classes.autoCompleate]}
                          options={
                            isPreviousPageTemplate()
                              ? dataList?.typeList
                              : dataList?.typeList?.filter(
                                  (each) =>
                                    each.blooms?.includes(
                                      templateFrom?.section[i]?.question[index]?.bloom?.id
                                    ) &&
                                    each.difficulty?.includes(
                                      templateFrom?.section[i]?.question[index]?.difficult
                                        ?.id
                                    )
                                ) || []
                          }
                          getOptionLabel={(option) => option.type_name}
                          // disableClearable
                          onChange={(e, newValue) => {
                            onChange('type', i, index, newValue);
                          }}
                          getOptionSelected={(option, value) => option?.id === value?.id}
                          value={templateFrom?.section[i]?.question[index]?.type || {}}
                          size='small'
                          variant='outlined'
                          margin='dense'
                          disabled={
                            templateFrom?.questionGenerate ||
                            templateFrom?.selectTemplate === 2
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              style={{
                                width: '95%',
                                // backgroundColor: "#FFDADA",
                              }}
                              className={[classes.typeField, classes.textField]}
                              placeholder='Type'
                              variant='outlined'
                              margin='dense'
                              // value={val.type || ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item lg={4} md={3} sm={5} xs={2}>
                        <Tooltip
                          title={
                            templateFrom?.section[i]?.question[index]?.subType
                              ?.type_name || ''
                          }
                          placeholder='top'
                        >
                          <Autocomplete
                            className={[classes.autoCompleate]}
                            options={
                              isPreviousPageTemplate()
                                ? templateFrom?.section[i].question[index]?.type
                                  ? templateFrom?.section[i]?.question[index]?.type
                                    ? dataList.subTypeList.filter(
                                        (each) =>
                                          each.sub_type_id ===
                                          templateFrom?.section[i]?.question[index]?.type
                                            ?.id
                                      )
                                    : dataList?.subTypeList
                                  : []
                                : dataList?.subTypeList?.filter(
                                    (each) =>
                                      each.blooms?.includes(
                                        templateFrom?.section[i]?.question[index]?.bloom
                                          ?.id
                                      ) &&
                                      each.difficulty?.includes(
                                        templateFrom?.section[i]?.question[index]
                                          ?.difficult?.id
                                      ) &&
                                      each.type?.includes(
                                        templateFrom?.section[i]?.question[index]?.type
                                          ?.id
                                      )
                                  ) || []
                            }
                            getOptionLabel={(option) => option.type_name}
                            onChange={(e, newValue) => {
                              onChange('subType', i, index, newValue);
                            }}
                            getOptionSelected={(option, value) =>
                              option?.id === value?.id
                            }
                            value={
                              templateFrom?.section[i]?.question[index]?.subType || {}
                            }
                            size='small'
                            variant='outlined'
                            margin='dense'
                            disabled={
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                style={{
                                  width: '95%',
                                }}
                                className={[classes.typeField, classes.textField]}
                                placeholder='Sub Type: Select'
                                variant='outlined'
                                margin='dense'
                                name='subType'
                                // value={val.subType || ''}
                              />
                            )}
                            components={{
                              ClearIndicator: (props) => (
                                <div style={{ paddingRight: '25px' }}>
                                  {props.children}{' '}
                                </div>
                              ),
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={5}
                        xs={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justify: 'space-evenly',
                        }}
                      >
                        <Typography
                          className='font-size-12'
                          style={{ marginRight: '1%' }}
                        >
                          Marks
                        </Typography>
                        <TextField
                          className={[classes.number, classes.textField]}
                          label=''
                          margin='dense'
                          style={{ width: '40%' }}
                          required
                          variant='outlined'
                          value={templateFrom?.section[i]?.question[index]?.marks || ''}
                          disabled={
                            templateFrom?.questionGenerate ||
                            templateFrom?.selectTemplate === 2
                          }
                          onChange={(e) => {
                            // let myTemplateForm = { ...templateFrom };
                            // const updatedSection = [...myTemplateForm.section];
                            // updatedSection[i].question[index].marks =
                            //   e.target.value;

                            // setTemplateFrom({
                            //   ...myTemplateForm,
                            //   section: updatedSection,
                            // });
                            onChange('marks', i, index, e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item lg={1} md={1} sm={2} xs={2}>
                        <IconButton
                          style={{
                            color: `${
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                                ? ''
                                : 'red'
                            }`,
                            padding: '0',
                          }}
                          disabled={
                            templateFrom?.questionGenerate ||
                            templateFrom?.selectTemplate === 2
                          }
                          onClick={() =>
                            setDeleteQuestionModalOpen({
                              open: true,
                              sectionIndex: i,
                              questionIndex: index,
                            })
                          }
                        >
                          <RemoveCircleOutline style={{ width: 18, height: 18 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item container justify='center'>
                <Grid item>
                  <Button
                    variant='outlined'
                    color='primary'
                    style={{ height: '30px', padding: '0 10px !important' }}
                    disabled={
                      templateFrom?.questionGenerate || templateFrom?.selectTemplate === 2
                    }
                    onClick={() => handleIncrement(i)}
                  >
                    Add question
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <br />
          </div>
        );
      })}
      <Grid container spacing={1} xs={12} alignItems='flex-end' justify='flex-end'>
        <Grid item>
          <Button
            variant='outlined'
            size='small'
            color='primary'
            style={{ height: '30px', padding: '0 10px !important' }}
            onClick={() => goToStep(2)}
          >
            Back
          </Button>
        </Grid>
        {history.location?.state?.data?.previousURL ===
        '/auto-assessment/question-paper' ? (
          <>
            <Grid item style={{ paddingRight: '0' }}>
              {currentStep === 4 && templateFrom?.questionGenerate && (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ height: '30px', padding: '0 10px !important' }}
                  startIcon={<LoopOutlined />}
                  onClick={() => {
                    changeQuestionGenerate();
                  }}
                >
                  Regenerate
                </Button>
              )}
              {/* templateFrom?.questionGenerate && !templateFrom?.section?.filter(eachSection => eachSection?.question?.filter(eachQuestion => !eachQuestion?.question)?.length)?.length */}
              {currentStep === 3 && (
                <Button
                  variant='contained'
                  color='primary'
                  style={{ width: '120%', height: '30px', padding: '0 10px !important' }}
                  size='small'
                  startIcon={<LoopOutlined />}
                  onClick={() => checkNextPage(currentStep)}
                >
                  Generate
                </Button>
              )}
            </Grid>
            <Grid item>
              {currentStep === 4 && templateFrom?.questionGenerate && (
                <Grid container spacing={0}>
                  <Grid item>
                    <select
                      className={classes.publishSelect}
                      name='exportType'
                      value={templateFrom?.exportType}
                      onChange={(e) => onChange('exportType', null, null, e.target.value)}
                    >
                      {/* <option className={classes.publishSelectOption} value='Publish'>
                        Publish
                      </option> */}
                      {/* <option
                        className={classes.publishSelectOption}
                        value='Save Template'
                      >
                        Save Template
                      </option> */}
                      {/* <option
                        className={classes.publishSelectOption}
                        value='Publish & Save Template'
                      >
                        Publish & Save Template
                      </option> */}
                      <option className={classes.publishSelectOption} value='Draft'>
                        create paper for draft
                      </option>
                      <option className={classes.publishSelectOption} value='Review'>
                        create paper for review
                      </option>
                      <option
                        className={classes.publishSelectOption}
                        value='Review & Save Template'
                      >
                        create paper for review and save template
                      </option>
                    </select>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      className={classes.publishButton}
                      onClick={() => handleExportOptions()}
                    >
                      <Send />
                    </Button>
                  </Grid>
                </Grid>
              )}
              {currentStep === 4 && !templateFrom?.questionGenerate && (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ height: '30px', padding: '0 10px !important' }}
                  startIcon={<LoopOutlined />}
                  onClick={() => regenerate()}
                >
                  Confirm Criteria
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <Grid item>
            <Button
              variant='contained'
              size='small'
              color='primary'
              style={{ height: '30px', padding: '0 10px !important' }}
              onClick={() => checkNextPage(currentStep)}
            >
              {/* {isEdit()?"Edit Template":"Create Template"} */}
              {isEdit() ? 'Update Template' : 'Save Template'}
              {/* Save Template */}
            </Button>
          </Grid>
        )}
      </Grid>
      {eachQuestionPreviewModal}
      {deleteQuestionModal}
    </>
  );
};

export default withStyles(styles)(CreateSection);

import React from 'react';
import {
  Divider,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from '../style';
import OrchidsLogo from '../../../../assets/images/orchidsLogo1.png';
import moment from 'moment';
import { getArrayValues } from '../../../../utility-functions';
const ChooseTemplate = ({ classes, templateFrom, currentStep }) => {
  return (
    <>
      <TableContainer style={{ backgroundColor: '#fff' }}>
        <Table size='small'>
          <TableRow className={classes.teblerow}>
            <TableCell
              colspan='2'
              rowspan='3'
              align='center'
              className={[classes.tablecell, classes.tablecellimage]}
            >
              <img src={OrchidsLogo} alt='OrchidsLogo' className={classes.tablelogo} />
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Name</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}></TableCell>
          </TableRow>
          <TableRow className={classes.teblerow}>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Erp</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}></TableCell>
          </TableRow>
          <TableRow className={classes.teblerow}>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Section</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}></TableCell>
          </TableRow>
          {/* <TableRow className={[classes.teblerow, classes.backgroundlightgray]}>
            <TableCell colspan='6' align='center' className={classes.tablecell}>
              <b>
                {typeof templateFrom?.volume === 'object'
                  ? templateFrom?.volume?.map((each) => each?.volume_name)?.join(',')
                  : ''}
                -{' '}
                {typeof templateFrom?.chapter === 'object'
                  ? templateFrom?.chapter?.map((each) => each?.chapter_name)?.join(',')
                  : ''}
              </b>
            </TableCell>
          </TableRow> */}
          {/* <TableRow className={classes.teblerow}>
            <TableCell colspan='6' align='center' className={classes.tablecell}>
              <b>weekly test {templateFrom?.academic_year?.session_year}</b>
            </TableCell>
          </TableRow> */}
          <TableRow className={[classes.teblerow, classes.backgroundlightgray]}>
            <TableCell className={classes.tablecell}>
              <b>Grade - {templateFrom?.grade?.grade_name}</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>Subject - {templateFrom?.subject?.subject?.subject_name}</b>
            </TableCell>
            <TableCell className={classes.tablecell}>
              <b>Marks: {templateFrom?.total_marks}</b>
            </TableCell>
            <TableCell colspan='2' className={classes.tablecell}>
              <b>{`Duration: ${templateFrom?.duration} minutes`}</b>
            </TableCell>
          </TableRow>
          <TableRow className={[classes.teblerow, classes.backgroundlightgray]}>
            {/* <TableCell colspan='4' className={classes.tablecell}>
              <b>Test ID: 34565</b>
            </TableCell> */}
            <TableCell colspan='6' className={classes.tablecell}>
              <b>Date: {moment(new Date()).format('DD.MM.YYYY')}</b>
            </TableCell>
          </TableRow>
          <TableRow className={classes.teblerow}>
            <TableCell colspan='6' className={classes.tablecell}>
              <strong>General Instruction</strong>
              <Divider />
              <pre className={classes.instruction}>{templateFrom?.instruction}</pre>
              <br />
              {/* {currentStep > 1 &&
                templateFrom.section?.map((eachSection) => {
                  return (
                    <>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classes.previewSectionHeader}
                      >
                        {eachSection?.header}
                      </Typography>
                      <Typography
                        variant="body2"
                        align="center"
                        className={classes.previewSectionDescription}
                      >
                        {eachSection?.description}
                      </Typography>
                      {eachSection?.question?.map((eachQuestion, index) => {
                        return (
                          <>
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item style={{ minWidth: "20px" }}>
                                <Typography variant="body2">
                                  {index + 1}.
                                </Typography>
                              </Grid>
                              <Grid item>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eachQuestion?.question
                                      ?.question_answer?.length
                                      ? eachQuestion?.question
                                          ?.question_answer[0]?.question
                                      : null,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {eachQuestion?.question?.question_answer[0]
                              ?.options &&
                            eachQuestion?.question?.question_answer[0]?.options
                              .length ? (
                              <>
                                {eachQuestion?.question?.question_answer
                                  ?.length ? (
                                  <>
                                    <Grid container spacing={1}>
                                      {eachQuestion?.question?.question_answer[0]?.options?.map(
                                        (eachOption, index) => {
                                          return (
                                            <>
                                              <Grid
                                                container
                                                item
                                                xs={6}
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <Typography
                                                    variant="body2"
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {String.fromCharCode(
                                                      65 + index
                                                    )}
                                                    .
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <Typography variant="body2">
                                                    {eachOption[
                                                      `option${index + 1}`
                                                    ]?.title
                                                      ? eachOption[
                                                          `option${index + 1}`
                                                        ]?.title
                                                      : eachOption[
                                                          `option${index + 1}`
                                                        ]?.optionValue
                                                      ? eachOption[
                                                          `option${index + 1}`
                                                        ]?.optionValue
                                                      : null}
                                                  </Typography>
                                                  {eachOption[
                                                    `option${index + 1}`
                                                  ]?.images?.length
                                                    ? eachOption[
                                                        `option${index + 1}`
                                                      ]?.images?.map(
                                                        (
                                                          eachImage,
                                                          indexImage
                                                        ) => {
                                                          return (
                                                            <>
                                                              <img
                                                                src={eachImage}
                                                                alt="option image"
                                                              />
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    : null}
                                                </Grid>
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  );
                })} */}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
      {templateFrom?.section?.map((eachSection, sectionIndex) => {
        return (
          <div
            key={sectionIndex}
            style={{
              width: '100%',
              margin: '10px 0',
              border: '1px solid #F2F2F2',
            }}
          >
            <div style={{ padding: '5px 10px', backgroundColor: '#F2F2F2' }}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item>
                  <Typography
                    variant='h6'
                    className='font-size-12'
                    style={{ fontWeight: 'bold' }}
                  >
                    {eachSection?.header}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6' className='font-size-12'>
                    {eachSection?.totalQuestion} Qns ·{' '}
                    {eachSection?.question.reduce((accumulator, currentValue) => {
                      return accumulator + parseFloat(currentValue?.marks);
                    }, 0)}{' '}
                    Marks
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: '10px 10px' }}>
              <TableContainer className={classes.chooseTemplateTableContainer}>
                <Table size='small' className={classes.chooseTemplateTable}>
                  <TableHead className={classes.chooseTemplateTableHead}>
                    <TableRow className={classes.chooseTemplateTableRow}>
                      <TableCell className={classes.chooseTemplateCell}>Qns.</TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Blooms Level
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Difficulty Level
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Q. Type
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Q. Sub Type
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eachSection?.question?.map((eachQuestion, questionIndex) => {
                      return (
                        <TableRow
                          key={questionIndex}
                          className={classes.chooseTemplateTableRow}
                        >
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {questionIndex + 1}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.bloom?.category_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.difficult?.level_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.type?.type_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.subType?.type_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.marks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default withStyles(styles)(ChooseTemplate);

/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import IconButton from '@material-ui/core/IconButton';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BlockIcon from '@material-ui/icons/Block';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Layout from '../Layout';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import endpoints from '../../config/endpoints';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import axiosInstance from '../../config/axios';
import CreateEditSchool from './create-edit-school';
import Loading from '../../components/loader/loader';
import './centraldash.css'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { message } from 'antd';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90vw',
    width: '95%',
    margin: '20px auto',
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    maxWidth: '90vw',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
}));

const CentralDashboard = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const { role, user_id } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [page, setPage] = useState(1);
  const [schools, setSchools] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [schoolName, setSchoolName] = useState('');
  const [addEditFlag, setAddEditFlag] = useState(false);
  const [tableFlag, setTableFlag] = useState(true);
  const [delFlag, setDelFlag] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [searchSchool, setSearchSchool] = useState('');
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState()
  const [active, setActive] = useState()
  const [limit, setLimit] = useState(15)
  const statusList = ['Pending', 'Activated', 'Deactivated', 'Deleted'];
  const [statusId, setStatusId] = useState('')
  const [updateListFlag, setUpdateListFlag] = useState(false)
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [mobileViewFlag, setMobileViewFlag] = useState(window.innerWidth < 700)
  const [dataCount, setDataCount] = useState()
  const [selectedMultipleRoles, setSelectedMultipleRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [boardidFilter, setboardIdFilter] = useState([])
  const [boardList, setboardList] = useState([])
  const [selectBoard, setselectBoard] = useState([])
  const [boardIds, setBoardIds] = useState('');
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px'
  const widerWidth = isMobile ? '98%' : '95%'
  const getSchool = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.board}`)
      .then((result) => {
        setboardList(result.data.result)
      })
      .catch((error) => {
        console.log(error);
      });
  }



  const handleSchool = (event = {}, value = []) => {
    if (value && value.id !== null) {
      const ids = value.map((el) => el);
      const selectedId = value.map((el) => el?.id);
      setselectBoard(ids);
      setBoardIds(selectedId);
    } else {
      setBoardIds([]);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleAddSchool = () => {
    setTableFlag(false);
    setAddEditFlag(true);
  };

  const handleEditSchool = (id, logo, name, email, contact, city, schoolCode, address, domain, affiliation_number, boards) => {
    setTableFlag(false);
    setAddEditFlag(true);
    setEditData({
      id: id,
      logo: logo,
      name: name,
      email: email,
      contact: contact,
      city: city,
      schoolCode: schoolCode,
      address: address,
      domain: domain,
      affiliation_number: affiliation_number,
      // boards:boards?.[0]?.board_name,
      boards: boards,

    });
  };

  const handleStatus = (event, value) => {
    setStatusId('');
    if (value) {
      setPage(1)
    }
    if (value === 'Pending') {
      setStatusId('0')
    }
    if (value === 'Activated') {
      setStatusId('1')
    }
    if (value === 'Deactivated') {
      setStatusId('2')
    }
    if (value === 'Deleted') {
      setStatusId('3')
    }
  };

  const handleGoBack = () => {
    setTableFlag(true)
    setAddEditFlag(false)
    setSearchSchool('')
    setEditData()
    setUpdateListFlag(!updateListFlag)
  };

  const handleOpenDeleteModal = (id) => {
    setSchoolId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleStatusChange = (id, val) => {
    setLoading(true)

    let request, payload
    if (val) {
      request = endpoints.school.activateSchool
      payload = {
        id: id,
        is_active: '1',
      }
    }
    else {
      request = endpoints.school.deactivateSchool
      payload = {
        id: id,
        status: '2',
      }
    }

    axiosInstance.put(request, payload)
      .then((result) => {
        if (result.status === 200) {
          if (val)
            setAlert('success', "School activated successfully!")
          else
            setAlert('success', "School deactivated successfully!")
          setLoading(false)
          setActive(true)
        }
        else {
          if (val)
            setAlert('error', "School couldn't be activated!")
          else
            setAlert('error', "School couldn't be deactivated!")
          setLoading(false)
          setActive(false)
        }
      }).catch((error) => {
        if (val){
          if(error?.response?.status == 403){
          setAlert('error', error?.response?.data?.description)
          } else {
            setAlert('error', "School couldn't be activated!")
          }
          setLoading(false)
          setActive(false)
        }
        else{
          setAlert('error', "School couldn't be deactivated!")
          setLoading(false)
          setActive(false)
        }
      })
  }

  const handleDeleteSchool = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .put(endpoints.school.deleteSchool, {
        id: schoolId,
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          setDelFlag(!delFlag);
          setLoading(false);
          setAlert('success', result.data.message);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.response.data.description);
      });
    setOpenDeleteModal(false);
  };

  const migrateSchool = (url) => {
    axios.get(`https://${url}.letseduvate.com/qbox/erp_user/check_migrations/?user_number=${user_id}`)
      .then((result) => {
        // console.log(result.data);
        // setboardList(result.data.result)
        // setAlert('success', result?.data?.result)
        message.success(result?.data?.result)
      })
      .catch((error) => {
        console.log(error);
        message.error("Failed to Migrate")
        // setAlert('success', error)
      });
  }

  useEffect(() => {
    let requestUrl = `${endpoints.school.listSchool}?page=${page}&page_size=${limit}`;

    if (statusId !== "")
      requestUrl += `&status=${statusId}`

    if (searchSchool)
      requestUrl += `&search=${searchSchool}`;
    if (boardIds !== '')
      requestUrl += ` &board_ids=${boardIds} `;



    axiosInstance.get(requestUrl)
      .then(result => {
        if (result.data.status_code === 200) {
          setSchools(result.data.result);
          setPageCount(result.data.total_pages)
          setDataCount(result.data.count)
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error?.response?.data.description);
      });
  }, [delFlag, page, active, updateListFlag, searchSchool, statusId, boardList, boardIds])

  useEffect(() => {
    getSchool()
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 450);
  }, [page, delFlag, active])

  useEffect(() => {
    if (isMobile)
      setMobileViewFlag(true)
    else
      setMobileViewFlag(false)
  }, [isMobile])

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div className='breadcrumb_wrapper'>
          <CommonBreadcrumbs
            componentName='School Management'
            childComponentName={(editData && !tableFlag) ? 'Edit School' : (!editData && !tableFlag) ? 'Add School' : null}
          />
        </div>
        {!tableFlag && addEditFlag &&
          <CreateEditSchool editData={editData} handleGoBack={handleGoBack} setLoading={setLoading} />}

        {tableFlag && !addEditFlag &&
          <>
            <Grid container spacing={isMobile ? 3 : 5} style={{ width: widerWidth, margin: wider }}>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
                <TextField
                  id='searchschool'
                  label='School Name'
                  variant='outlined'
                  style={{ width: '100%' }}
                  size='small'
                  name='searchschool'
                  autoComplete="off"
                  onChange={e => { setPage(1); setSearchSchool(e.target.value); }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  size='small'
                  onChange={handleStatus}
                  id='status'
                  style={{ width: '100%' }}
                  options={statusList}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Status'
                      placeholder='Status'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  style={{ width: '100%' }}
                  size='small'
                  onChange={handleSchool}
                  id='branch_id'
                  className='dropdownIcon'
                  value={selectBoard || []}
                  options={boardList || []}
                  getOptionLabel={(option) => option?.board_name || ''}
                  getOptionSelected={(option, value) =>
                    option?.id == value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='board'
                      placeholder='board'
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={0} sm={6} className={isMobile ? 'hide' : ''} /> */}
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addButtonPadding'}>
                {tableFlag && !addEditFlag &&
                  <Button
                    startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                    title="Add School"
                    variant='contained'
                    color='primary'
                    size="small"
                    style={{ color: 'white' }}
                    onClick={handleAddSchool}>
                    Add School
                  </Button>
                }
              </Grid>
            </Grid>
          </>
        }



        {tableFlag && !addEditFlag &&
          <Paper className={`${classes.root} view_user_table_wrapper`}>
            <div>
              {isMobile && tableFlag ? (
                mobileViewFlag ?
                  <div className='mobileFlagDisp' onClick={() => setMobileViewFlag(false)}>View More</div >
                  : <div className='mobileFlagDisp' onClick={() => setMobileViewFlag(true)}>View Less</div>
              ) : null}
            </div>
            <TableContainer className={`table view_user_table table-shadow ${classes.container}`}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead className='view_groups_header'>
                  <TableRow>
                    <TableCell
                      key='school_name'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={classes.columnHeader}>
                      School
                    </TableCell>


                    <TableCell
                      key='school_email'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={mobileViewFlag ? `hide ${classes.columnHeader}` : `show  ${classes.columnHeader}`}>
                      Email
                    </TableCell>

                    <TableCell
                      key='school_contact'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={mobileViewFlag ? `hide ${classes.columnHeader}` : `show  ${classes.columnHeader}`}>
                      Contact
                    </TableCell>

                    <TableCell
                      key='school_city'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={mobileViewFlag ? `hide ${classes.columnHeader}` : `show  ${classes.columnHeader}`}>
                      City
                    </TableCell>

                    <TableCell
                      key='school_sub_domain_name'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={mobileViewFlag ? `hide ${classes.columnHeader}` : `show  ${classes.columnHeader}`}>
                      Sub-Domain
                    </TableCell>

                    <TableCell
                      key='status'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={classes.columnHeader}>
                      Status
                    </TableCell>


                    <TableCell
                      key='actions'
                      align='center'
                      style={{ minWidth: '100' }}
                      className={mobileViewFlag ? `hide ${classes.columnHeader}` : `show  ${classes.columnHeader}`}>
                      Actions
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {schools.map((school, index) => {
                    return (
                      <TableRow hover school='checkbox' tabIndex={-1} key={index}>
                        <TableCell className={classes.tableCell}>
                          {school.school_name}
                        </TableCell>
                        <TableCell className={mobileViewFlag ? `hide ${classes.tableCell}` : `show  ${classes.tableCell}`}>
                          {school.school_email}
                        </TableCell>
                        <TableCell className={mobileViewFlag ? `hide ${classes.tableCell}` : `show  ${classes.tableCell}`}>
                          {school.school_contact}
                        </TableCell>
                        <TableCell className={mobileViewFlag ? `hide ${classes.tableCell}` : `show  ${classes.tableCell}`}>
                          {school.city}
                        </TableCell>
                        <TableCell className={mobileViewFlag ? `hide ${classes.tableCell}` : `show  ${classes.tableCell}`}>
                          {school.school_sub_domain_name}
                        </TableCell>
                        <TableCell>
                          {school.status === '0' ?
                            <div style={{ color: '#FF6B6B', fontWeight: '600' }}>Pending</div>
                            : school.status === '1' ?
                              <div style={{ color: 'green', fontWeight: '600' }}>Activated</div>
                              : school.status === '2' ?
                                <div style={{ color: 'red', fontWeight: '600' }}>Deactivated</div>
                                : null
                          }
                        </TableCell>

                        <TableCell className={mobileViewFlag ? `hide ${classes.tableCell}` : `show  ${classes.tableCell}`}>
                          {role && role.role_name !== "Marketing" && school.status === '1' ? (
                            <IconButton title='Deactivate' onClick={() => handleStatusChange(school.id, false)}>
                              <BlockIcon style={{ color: '#fe6b6b' }} />
                            </IconButton>
                          ) : (role && role.role_name !== "Marketing" && (school.status === '0' || school.status === '2')) ? (
                            <button
                              type='submit'
                              title='Activate'
                              onClick={() => handleStatusChange(school.id, true)}
                              style={{
                                borderRadius: '50%',
                                backgroundColor: 'green',
                                border: 0,
                                width: '30px',
                                height: '30px',
                                color: '#ffffff',
                                cursor: 'pointer',
                              }}
                            >
                              A
                            </button>
                          ) : null}

                          {role && role.role_name !== "Marketing" ?
                            <IconButton
                              onClick={e => { setSchoolName(school.school_name); handleOpenDeleteModal(school.id); }}
                              title='Delete School'
                              style={{padding: 8}}
                            >
                              <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                            </IconButton>
                            : null}

                          {role && role.role_name !== "Marketing" || (role && role.role_name === "Marketing" && !school.is_active) ?
                            <IconButton
                              onClick={e => handleEditSchool(
                                school.id,
                                school.school_logo,
                                school.school_name,
                                school.school_email,
                                school.school_contact,
                                school.city,
                                school.school_code,
                                school.address,
                                school.school_sub_domain_name,
                                school.affiliation_number,
                                school.boards
                              )}
                              style={{padding: 8}}
                              title='Edit School'
                            >
                              <EditOutlinedIcon style={{ color: '#fe6b6b', fontSize: 25  }} />
                            </IconButton>
                            : null}

                          {role && role.role_name !== "Marketing" && 
                            <IconButton title='Migrate School' style={{padding: 8}}>
                              <SettingsEthernetIcon onClick={() => migrateSchool(school.school_sub_domain_name)} style={{ color: '#fe6b6b' }} />
                            </IconButton>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="paginate paginateMobileMargin">
              <TablePagination
                component='div'
                count={dataCount}
                rowsPerPage={limit}
                page={page - 1}
                onChangePage={handleChangePage}
                rowsPerPageOptions={false}
              />
            </div>
          </Paper>
        }
        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby='draggable-dialog-title'
        >
          <DialogTitle style={{ color: '#014b7e', cursor: 'move' }} id='draggable-dialog-title'>
            Delete School
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Confirm Delete School ${schoolName}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} className="labelColor cancelButton">
              Cancel
            </Button>
            <Button color="primary" variant="contained" style={{ color: 'white' }} onClick={handleDeleteSchool}>Confirm</Button>
          </DialogActions>
        </Dialog>

      </Layout>
    </>
  );
};

export default CentralDashboard;

import React, { useState, useEffect } from 'react';
import MyTinyEditor from '../../assesment/assessment-new/create-question-paper-new/tiny-mce-new';
import {
  PlusOutlined,
  DeleteOutlined,
  FileImageOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Button, Switch, Checkbox, Input, Upload, message, Progress, Modal } from 'antd';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import cuid from 'cuid';

const FillInBlanksQuestion = ({
  currentQuestion,
  isSubQuestion,
  handleQuestionChanges,
  subQuestionIndex,
  isEditQuestion,
}) => {
  const [fillInBlanksQuestion, setFillInBlanksQuestion] = useState({
    question: currentQuestion?.question_answer[0]?.question,
    answer: currentQuestion?.question_answer[0]?.answer,
    options: currentQuestion?.question_answer[0]?.options,
  });
  const [questionUpdated, setQuestionUpdated] = useState(isEditQuestion);
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [percentValue, setPercentValue] = useState(10);

  const handleEditorChange = (content, editor) => {
    // if (isSubQuestion) {
    //   return;
    // } else {
    setQuestionUpdated(false);
    setFillInBlanksQuestion({ ...fillInBlanksQuestion, question: content });
    setTimeout(() => {
      setQuestionUpdated(true);
    }, 100);
  };
  const allowedFiles = ['.png', '.jpg', '.jpeg'];

  const handleFillInBlanksContent = () => {
    return currentQuestion?.question_answer[0]?.question;
    // }
  };
  const handleOptionData = (e, index) => {
    setQuestionUpdated(false);
    if (e.target.name == 'optionValue') {
      let currentOptionList = [...fillInBlanksQuestion?.options];
      currentOptionList[index][`option${index + 1}`]['optionValue'] = e.target.value;
      let currentAnswers = [...fillInBlanksQuestion?.answer];
      if (index < fillInBlanksQuestion?.answer.length) {
        currentAnswers[index] = e.target.value;
      } else {
        currentAnswers.push(e.target.value);
      }
      setFillInBlanksQuestion({
        ...fillInBlanksQuestion,
        answer: currentAnswers,
        options: currentOptionList,
      });
      setTimeout(() => {
        setQuestionUpdated(true);
      }, 100);
    } else if (e.target.name == 'images') {
      const selectedImage = e.target?.files[0];
      const type =
        '.' + selectedImage?.name?.split('.')[selectedImage?.name?.split('.').length - 1];
      if (allowedFiles.includes(type)) {
        if (selectedImage?.size > 52428800) {
          message.error('MP4 Size Cannot Exceed 50MB');
          return;
        } else {
          handleImageUpload(selectedImage, index);
        }
      } else {
        message.error('Please upload images only');
      }
    }
  };

  let idInterval = null;
  useEffect(() => {
    if (fileUploadInProgress == true && percentValue < 90) {
      idInterval = setInterval(
        () => setPercentValue((oldCount) => checkCount(oldCount)),
        1000
      );
    }
    if (fileUploadInProgress == true && percentValue < 90) {
      idInterval = setInterval(
        () => setPercentValue((oldCount) => checkCount(oldCount)),
        100
      );
    }

    return () => {
      clearInterval(idInterval);
      setPercentValue(10);
    };
  }, [fileUploadInProgress]);

  const checkCount = (count) => {
    if (count < 90) {
      return count + 5;
    } else {
      return count;
    }
  };
  const handleImageUpload = (file, index) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'grade_name',
      currentQuestion?.topic?.grade_subject_mapping?.grade?.grade_name
    );
    formData.append(
      'subject_name',
      currentQuestion?.topic?.grade_subject_mapping?.subject?.subject_name
    );
    formData.append('question_categories', currentQuestion?.question_categories);
    formData.append('question_type', currentQuestion?.question_type?.id);
    setFileUploadInProgress(true);
    axiosInstance
      .post(`${endpoints.questionBank.uploadFile}`, formData)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          message.success('File uploaded successfully');
          let currentOptionList = [...fillInBlanksQuestion?.options];
          currentOptionList[index][`option${index + 1}`]['images'].push(
            result?.data?.result
          );
          setFillInBlanksQuestion({
            ...fillInBlanksQuestion,
            options: currentOptionList,
          });
          setTimeout(() => {
            setQuestionUpdated(true);
          }, 100);
        } else {
          message.error(result?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setFileUploadInProgress(false);
      });
  };

  const handleDeleteFile = (rowIndex, imageIndex) => {
    setQuestionUpdated(false);
    const list = [...fillInBlanksQuestion?.options];
    axiosInstance
      .post(`${endpoints.questionBank.removeFile}`, {
        file_name: list[rowIndex][`option${rowIndex + 1}`]['images'][imageIndex],
      })
      .then((result) => {
        if (result?.data?.status_code === 204) {
          list[rowIndex][`option${rowIndex + 1}`]['images'].splice(imageIndex, 1);
          setFillInBlanksQuestion({ ...fillInBlanksQuestion, options: list });
          message.success(result?.data?.message);
          setTimeout(() => {
            setQuestionUpdated(true);
          }, 100);
        } else {
          message.error(result?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error?.message);
      });
  };

  const handleDeleteOption = (deleteIndex) => {
    setQuestionUpdated(false);
    let updatedOptions = fillInBlanksQuestion?.options?.filter(
      (item, index) => index != deleteIndex
    );
    setFillInBlanksQuestion({ ...fillInBlanksQuestion, options: updatedOptions });
    setTimeout(() => {
      setQuestionUpdated(true);
    }, 100);
  };

  const handleAddOption = () => {
    setQuestionUpdated(false);
    let newEmptyOption = { images: [], isChecked: false, optionValue: '' };
    let currentOptions = [...fillInBlanksQuestion?.options];
    currentOptions.push({
      [`option${fillInBlanksQuestion?.options.length + 1}`]: newEmptyOption,
    });
    setFillInBlanksQuestion({ ...fillInBlanksQuestion, options: currentOptions });
    setTimeout(() => {
      setQuestionUpdated(true);
    }, 100);
  };

  useEffect(() => {
    if (questionUpdated) {
      if (isSubQuestion) {
        handleQuestionChanges(fillInBlanksQuestion, subQuestionIndex);
      } else {
        handleQuestionChanges(fillInBlanksQuestion);
      }
    }
  }, [questionUpdated]);

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='th-16 th-fw-600 th-black pb-2'>
            {isSubQuestion ? `Sub-Question ${subQuestionIndex + 1}` : 'Question'}
          </div>
          <div className='th-editor'>
            <MyTinyEditor
              id={`Fillintheblanks${cuid()}`}
              content={handleFillInBlanksContent()}
              handleEditorChange={handleEditorChange}
              setOpenEditor={true}
              placeholder='Question goes here...'
            />
          </div>
        </div>
        <div className='col-12'>
          <div className='p-2 mt-2 th-br-4' style={{ border: '1px solid #d9d9d9' }}>
            <div className='row align-items-center'>
              <div className='col-md-4 col-12'>
                <label
                  htmlFor='answer'
                  className='text-success'
                  style={{ fontWeight: 500 }}
                >
                  Answers
                </label>
              </div>
            </div>
            {fillInBlanksQuestion?.options
              ?.map((el, index) => el[`option${index + 1}`])
              .map((item, index) => (
                <div key={index}>
                  <div className='row align-items-center'>
                    <div className='col-11'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div>
                          {`Blank ${String.fromCharCode(index + 65).toUpperCase()}. `}
                        </div>
                        <Input
                          placeholder='Type the option here'
                          className='th-width-85'
                          onChange={(e) => handleOptionData(e, index)}
                          name='optionValue'
                          value={item?.optionValue}
                        />
                      </div>
                    </div>
                    <div className='col-1'>
                      {index > 0 && (
                        <DeleteOutlined
                          className='th-18 th-pointer th-red'
                          onClick={() => handleDeleteOption(index)}
                        />
                      )}
                    </div>
                  </div>
                  <div className='row mt-1'>
                    <div className='col-1'></div>
                    <div className='col-7'>
                      <p className='ml-3'>
                        {Array.isArray(item?.images) &&
                          item?.images?.length > 0 &&
                          item?.images?.map((imgitem, i) => (
                            <span key={i}>
                              <img
                                src={`${endpoints.s3}${imgitem}`}
                                alt='Not found'
                                className='optionImageAttachment'
                                style={{
                                  marginLeft: '15px',
                                  marginTop: '10px',
                                  marginRight: '0',
                                }}
                              />
                              <CloseCircleOutlined
                                className='ml-2'
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleDeleteFile(index, i)}
                              />
                            </span>
                          ))}
                      </p>
                    </div>

                    <div className='col-3 pl-0 text-right'>
                      <label className='th-fw-500 th-pointer'>
                        <input
                          type='file'
                          onChange={(e) => {
                            if (item.images.length < 2) {
                              handleOptionData(e, index);
                            } else {
                              message.error('Only 2 images are allowed');
                            }
                          }}
                          value=''
                          name='images'
                          id='file-upload'
                          style={{ display: 'none' }}
                        />
                        <FileImageOutlined className='mr-1' /> Attach Image
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            <div className='row my-2 justify-content-center text-center'>
              <div className='col-12'>
                {fillInBlanksQuestion?.options?.length < 6 && (
                  <Button
                    className='btn add-another'
                    style={{ boxShadow: 'none' }}
                    onClick={handleAddOption}
                  >
                    <PlusOutlined /> Add another option
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        maskClosable={false}
        closable={false}
        footer={null}
        open={fileUploadInProgress}
        zIndex={1410}
        width={'50vw'}
        centered
      >
        <Progress
          strokeColor={{
            from: '#108ee9',
            to: '#87d068',
          }}
          percent={percentValue}
          status='active'
          className='p-4'
        />
      </Modal>
    </>
  );
};

export default FillInBlanksQuestion;

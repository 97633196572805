import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import styles from './createTemplate.style';
import {
  Add,
  RemoveCircleOutline,
  Edit,
  Close,
  Save,
  Done,
  BorderColorSharp,
} from '@material-ui/icons';
// import "../../cssStyles/page2.css";

const CreateSection = ({
  classes,
  templateFrom,
  onChange,
  handleAddMore,
  setTemplateFrom,
  goToStep,
  currentStep,
  checkNextPage,
  handleDelete,
  history,
}) => {
  const [clickEdit, setClickEdit] = useState(null);
  const [sectionName, setSectionName] = useState('');
  const handleEdit = (sectionIndex) => {
    setClickEdit(sectionIndex);
    setSectionName(templateFrom?.section[sectionIndex]?.header);
  };

  return (
    <>
      {/* <Grid
        container
        style={{
          padding: "1%",
          backgroundColor: "#F6F6F6",
          display: "flex",
          alignItems: "center",
          justify: "space-between",
          boxShadow: "0 0px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid item>
          <Typography>Grade</Typography>
        </Grid>
        <Grid item>
          <Typography>Subject</Typography>
        </Grid>
        <Grid item>
          <Typography>
            {"Vol 1 (chapter1, chapter 2...+3 More), Vol 2..."}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => goToStep(1)}
          >
            Edit
          </Button>
        </Grid>
      </Grid> */}
      {/* <br /> */}
      <Grid item sm={12}>
        <strong className='font-size-14'>Create Section</strong>
      </Grid>
      {history.location?.state?.data?.previousURL ===
        '/auto-assessment/question-paper' && (
        <Grid
          item
          style={{
            padding: '1%',
            backgroundColor: '#F6F6F6',
            boxShadow: '0 0px 4px rgba(0, 0, 0, 0.25)',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Grid
            container
            style={{
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <Grid item style={{ marginRight: '1%' }}>
              <IconButton onClick={() => goToStep(1)} style={{ padding: 0 }}>
                <BorderColorSharp color='black' style={{ width: 15, height: 15 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className='font-size-12'>
                {'Title:  ' + templateFrom?.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              alignItems: 'center',
              spacing: 2,
            }}
          >
            <Grid item lg={3} md={2} sm={2} xs={6}>
              <Typography className='font-size-12'>
                {templateFrom?.grade?.grade_name}
              </Typography>
            </Grid>
            <Grid item lg={3} md={2} sm={2} xs={5} className={classes.subject}>
              <Typography className='font-size-12'>
                {templateFrom?.subject?.subject?.subject_name}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={9} className='chapter'>
              <Typography className='font-size-12'>
                {typeof templateFrom?.volume === 'object' ? (
                  templateFrom?.volume.length > 2 ? (
                    <Tooltip
                      title={templateFrom?.volume
                        .map((each) => each?.volume_name)
                        .join(', ')}
                      placement='top'
                    >
                      <span>
                        {templateFrom?.volume
                          .slice(0, 2)
                          .map((each) => each?.volume_name)
                          .join(', ')}{' '}
                        <span style={{ color: 'grey' }}>
                          +{templateFrom?.volume.length - 2} more
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    templateFrom?.volume.map((each) => each?.volume_name).join(', ')
                  )
                ) : (
                  ''
                )}
                {' - '}
                {typeof templateFrom?.chapter === 'object' ? (
                  templateFrom?.chapter.length > 2 ? (
                    <Tooltip
                      title={templateFrom?.chapter
                        .map((each) => each?.chapter_name)
                        .join(', ')}
                      placement='top'
                    >
                      <span>
                        {templateFrom?.chapter
                          .slice(0, 2)
                          .map((each) => each?.chapter_name)
                          .join(', ')}{' '}
                        <span style={{ color: 'grey' }}>
                          +{templateFrom.chapter.length - 2} more
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    templateFrom?.chapter.map((each) => each?.chapter_name).join(', ')
                  )
                ) : (
                  ''
                )}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                color='primary'
                size='small'
                variant='contained'
                onClick={() => goToStep(1)}
              >
                Edit
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      )}

      {templateFrom?.section?.map((titles, i) => (
        <div key={i} className='mt-3'>
          <Grid
            item
            container
            sm={12}
            style={{
              alignItems: 'center',
            }}
          >
            {clickEdit === i ? (
              <Grid item style={{ marginRight: 5 }}>
                <TextField
                  className={[classes.number, classes.textField]}
                  label=''
                  placeholder='Enter Details'
                  margin='dense'
                  onChange={(e) => {
                    setSectionName(e.target.value);
                  }}
                  defaultValue={titles.header}
                  variant='outlined'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={(event) => {
                            let myTemplateForm = { ...templateFrom };
                            const updatedSection = [...myTemplateForm.section];
                            updatedSection[i].header = sectionName;

                            setTemplateFrom({
                              ...myTemplateForm,
                              section: updatedSection,
                            });
                            setClickEdit(null);
                            setSectionName('');
                          }}
                          style={{ padding: 0 }}
                        >
                          <Done style={{ width: 18, height: 18 }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : (
              <>
                <Grid item style={{ marginRight: 10 }}>
                  <Typography
                    style={{ fontWeight: 'bold', color: 'gray' }}
                    className='font-size-12'
                  >
                    {titles.header}
                  </Typography>{' '}
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() => handleEdit(i)}
                    style={{ padding: 0 }}
                  >
                    <Edit style={{ width: 18, height: 18 }} />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
          <br />
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justify: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              container
              spacing={2}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems='center'
              style={{
                boxShadow: '0 0px 4px rgba(0, 0, 0, 0.3)',
                marginLeft: '0.3%',
              }}
              key={i}
            >
              <Grid item xl={1} lg={0.5} md={0.5} sm={0.5} xs={1.1}>
                <Typography className='font-size-12'>Description</Typography>
              </Grid>
              <Grid item xl={5} lg={7} md={9} sm={9} xs={8}>
                <TextField
                  className={[classes.number, classes.textField]}
                  placeholder='Enter Details'
                  margin='dense'
                  fullWidth
                  multiline
                  onChange={(e) => {
                    let myTemplateForm = { ...templateFrom };
                    const updatedSection = [...myTemplateForm.section];
                    updatedSection[i].description = e.target.value;
                    setTemplateFrom({
                      ...myTemplateForm,
                      questionGenerate: false,
                      section: updatedSection,
                    });
                  }}
                  required
                  value={titles.description}
                  variant='outlined'
                  InputProps={{ classes: { input: classes.textJustify } }}
                />
              </Grid>
              <Grid item xl={1} lg={2} md={2.5} sm={2} xs={2.5}>
                <Typography className='font-size-12'>Total Questions</Typography>
              </Grid>
              <Grid
                item
                xl={4}
                lg={1}
                md={3}
                sm={3}
                xs={3.1}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justify: 'center',
                }}
                className={classes.incrementer}
              >
                <TextField
                  className={[classes.number, classes.textField]}
                  label=''
                  margin='dense'
                  style={{ width: '100%' }}
                  required
                  variant='outlined'
                  value={titles.totalQuestion}
                  disabled={templateFrom.selectTemplate === 2}
                  type='number'
                  InputProps={{ inputProps: { min: 0 } }}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      'Backspace',
                      'Delete',
                      'ArrowLeft',
                      'ArrowRight',
                    ];
                    if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }

                    const currentValue = e.target.value;
                    const newValue = currentValue + e.key;

                    // Allow only up to 3 digits
                    if (/^\d{4,}/.test(newValue)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    let myTemplateForm = { ...templateFrom };
                    const newTotalQuestions = e.target.value;
                    const updatedSection = [...myTemplateForm.section];
                    updatedSection[i].question = Array.from(
                      { length: newTotalQuestions },
                      () => ({
                        bloom: null,
                        difficult: null,
                        type: null,
                        subType: null,
                        marks: 0,
                      })
                    );
                    updatedSection[i].totalQuestion = newTotalQuestions;
                    setTemplateFrom({
                      ...myTemplateForm,
                      questionGenerate: false,
                      section: updatedSection,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item style={{ marginLeft: '1%' }}>
              {templateFrom.selectTemplate !== 2 && (
                <IconButton style={{ color: 'red' }} onClick={() => handleDelete(i)}>
                  <RemoveCircleOutline />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <br />
        </div>
      ))}
      <Box
        item
        display={'flex'}
        alignItems={'center'}
        justify={'center'}
        style={{ padding: '1%' }}
      >
        {templateFrom.selectTemplate !== 2 && (
          <Button
            onClick={handleAddMore}
            startIcon={<Add />}
            variant='outlined'
            color='primary'
            className={classes.addMoreButton}
            style={{ width: '20%' }}
          >
            Add More
          </Button>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        sm={12}
        md={12}
        justify='flex-end'
        style={{ marginTop: '10px' }}
      >
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={() => goToStep(currentStep - 1)}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            style={{ width: '110%' }}
            size='small'
            onClick={() => checkNextPage(currentStep)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CreateSection);

import React, { useState } from 'react';
import { Modal, Tag, Button, message, Spin } from 'antd';
import { EyeTwoTone, EditOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { AttachmentPreviewerContext } from '../../../components/attachment-previewer/attachment-previewer-contexts';
import endpoints from '../../../config/endpoints';
import { difficultyLevels, bloomLevels } from '../../../generalQuestionConstants';
import moment from 'moment';
import QuestionTypeHandler from '../QuestionTypesHandler/QuestionTypehandler';
import axiosInstance from '../../../config/axios';
import EditQuestionCard from '../EditQuestion/EditQuestionCard';
import cuid from 'cuid';
import _ from 'lodash';

const QuestionDetailsModal = ({
  questionViewModal,
  handleCloseViewQuestion,
  currentQuestion: question,
  isQuestionTagged,
  index,
  handleViewEditQuestion,
  getQuestionList,
  questionTypeList,
}) => {
  const { openPreview, closePreview } =
    React.useContext(AttachmentPreviewerContext) || {};
  const getS3DomainURL = (fileSrc) => {
    return `${endpoints.s3}${fileSrc}`;
  };
  const [enableEditMode, setEnableEditMode] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updatedQuestionDetails, setUpdatedQuestionDetails] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({ ...question });
  let initialQuestion = _.cloneDeep(currentQuestion);
  const isComprehensiveQuestion =
    currentQuestion?.sub_questions?.length > 0 ? true : false;

  const handleQuestionChanges = (questionDetails) => {
    setUpdatedQuestionDetails(questionDetails);
  };
  const handleCloseEnableMode = () => {
    setEnableEditMode(false);
    setCurrentQuestion(initialQuestion);
  };
  const handleUpdateQuestion = () => {
    let updatedQuestion = {};
    setUpdateLoading(true);
    if (isComprehensiveQuestion) {
      let subQuestions = updatedQuestionDetails?.sub_questions.map((item) => ({
        ...item,
        question_type: item?.question_type?.id,
        chapter: updatedQuestionDetails?.chapter?.id,
        topic: updatedQuestionDetails?.topic?.id,
      }));
      updatedQuestion = {
        sub_questions: subQuestions,
        question_answer: updatedQuestionDetails?.question_answer,
        question_level: updatedQuestionDetails?.question_level,
        question_categories: updatedQuestionDetails?.question_categories,
        question_type: updatedQuestionDetails?.question_type?.id,
        chapter: updatedQuestionDetails?.chapter?.id,
        topic: updatedQuestionDetails?.topic?.id,
        question_status: updatedQuestionDetails?.question_status,
        id: updatedQuestionDetails?.id,
        delete_questions: [],
      };
    } else {
      updatedQuestion = {
        id: currentQuestion?.id,
        question_answer: [updatedQuestionDetails],
        question_status: currentQuestion?.question_status,
        question_level: currentQuestion?.question_level,
        question_categories: currentQuestion?.question_categories,
        question_type: currentQuestion?.question_type?.id,
      };
    }
    console.log({ updatedQuestionDetails });
    axiosInstance
      .put(`v2/assessment/${currentQuestion?.id}/v3/questions/`, updatedQuestion)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseViewQuestion();
          message.success(response?.data?.message);
          getQuestionList();
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error?.message);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  const handleImageView = (data) => {
    return (
      <div>
        {data?.split('"')?.filter((str) => str.startsWith('https'))?.length > 0 && (
          <a
            onClick={() => {
              openPreview({
                currentAttachmentIndex: 0,
                attachmentsArray: (() => {
                  let newArray = data?.split('"');
                  let filtered = newArray.filter((str) => str.startsWith('https'));
                  const images = filtered || {};
                  const attachmentsArray = [];
                  images.forEach((image) => {
                    const attachmentObj = {
                      src: image,
                      name: `${image}`.split('.').slice(0, -1).join('.'),
                      extension: `.${`${image}`.split('.').slice(-1)[0]}`,
                    };
                    attachmentsArray.push(attachmentObj);
                  });
                  return attachmentsArray;
                })(),
              });
            }}
          >
            <EyeTwoTone />
          </a>
        )}
        {data?.split('"')?.filter((str) => str.startsWith('data:image')).length > 0 &&
          data
            ?.split('"')
            ?.filter((str) => str.startsWith('data:image'))
            ?.map((el, index) => {
              const dataUriParts = el.split(';');
              const mimeTypePart = dataUriParts[0].split(':')[1];
              const mimeTypeToExtension = {
                'image/jpeg': '.jpg',
                'image/png': '.png',
                'image/gif': '.gif',
                'image/jpg': '.jpg',
                // Add more mime types and extensions as needed
              };

              const fileExtension = mimeTypeToExtension[mimeTypePart] || 'unknown';
              return (
                <a
                  onClick={() => {
                    openPreview({
                      currentAttachmentIndex: 0,
                      attachmentsArray: [
                        {
                          src: el,
                          name: el,
                          extension: fileExtension,
                        },
                      ],
                    });
                  }}
                >
                  <EyeTwoTone />
                </a>
              );
            })}
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={questionViewModal}
        centered
        zIndex={1400}
        footer={
          enableEditMode ? (
            ''
          ) : isQuestionTagged ? (
            <div className='row'>
              <div className='col-12 pb-1 text-center'>
                <div className='d-flex justify-content-between '>
                  <Tag
                    color='magenta'
                    className='mb-2 th-width-20 text-truncate text-center'
                    title={
                      bloomLevels?.filter(
                        (el) => el?.id == currentQuestion?.question_categories
                      )[0]?.level
                    }
                  >
                    {
                      bloomLevels?.filter(
                        (el) => el?.id == currentQuestion?.question_categories
                      )[0]?.level
                    }
                  </Tag>
                  <Tag
                    color='orange'
                    className='mb-2 th-width-15 text-truncate text-center'
                    title={
                      difficultyLevels?.filter(
                        (el) => el?.id == currentQuestion?.question_level
                      )[0]?.level
                    }
                  >
                    {
                      difficultyLevels?.filter(
                        (el) => el?.id == currentQuestion?.question_level
                      )[0]?.level
                    }
                  </Tag>
                  <Tag
                    color='blue'
                    className='mb-2 th-width-10 text-truncate text-center'
                    title={currentQuestion?.question_type?.type?.type_name}
                  >
                    {currentQuestion?.question_type?.type?.type_name}
                  </Tag>
                  <Tag
                    color='green'
                    className='mb-2 th-width-30 text-truncate text-center'
                    title={currentQuestion?.question_type?.type_name}
                  >
                    {currentQuestion?.question_type?.type_name}
                  </Tag>
                  <Tag
                    color='cyan'
                    className='mb-2 th-width-15 text-truncate text-center'
                    title={
                      currentQuestion?.question_mark == 1
                        ? `${currentQuestion?.question_mark} Mark`
                        : `${currentQuestion?.question_mark} Marks`
                    }
                  >
                    {currentQuestion?.question_mark}{' '}
                    {currentQuestion?.question_mark == 1 ? 'Mark' : 'Marks'}
                  </Tag>
                </div>
              </div>
              <div className='col-12 pb-2 text-left'>
                <div className='th-12 th-fw-400 th-grey '>
                  Updated at : {moment(currentQuestion?.updated_at).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='col-12 pb-2 text-left'>
                <div className='th-12 th-fw-400 th-grey '>
                  Created at : {moment(currentQuestion?.created_at).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
          )
        }
        onCancel={handleCloseViewQuestion}
        title={
          <span className='pl-3'>{`${
            enableEditMode ? 'Edit' : 'Preview'
          } Question`}</span>
        }
        className='th-upload-modal'
        width={'70vw'}
      >
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {enableEditMode ? (
            <Spin tip='Updating question...' size='large' spinning={updateLoading}>
              <div className='px-1'>
                <EditQuestionCard
                  questionTypeList={questionTypeList}
                  question={currentQuestion}
                  isSubQuestion={false}
                  key={cuid()}
                  handleCloseEnableMode={handleCloseEnableMode}
                  getQuestionList={getQuestionList}
                  handleCloseViewQuestion={handleCloseViewQuestion}
                />
              </div>
            </Spin>
          ) : (
            <div className='mcq-container px-3'>
              <div className=''>
                {currentQuestion?.question_answer?.map((p) => (
                  <>
                    {p?.question ? (
                      <div className='d-flex justify-content-between'>
                        <div className='th-16 th-fw-600 th-black-1 '>Question </div>
                        {currentQuestion?.is_tagged && (
                          <div className='th-12 th-fw-400 th-grey '>
                            <Tag
                              icon={<EditOutlined />}
                              color='processing'
                              className='th-pointer th-16 px-2 p-1 th-fw-600'
                              onClick={() => {
                                // handleCloseViewQuestion();
                                // handleViewEditQuestion();
                                setEnableEditMode(true);
                              }}
                            >
                              Edit
                            </Tag>
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='question-tiny text-justify'>
                      {ReactHtmlParser(p?.question)}
                      {handleImageView(p?.question)}
                    </div>
                  </>
                ))}
              </div>
              {currentQuestion?.question_type?.type_name ==
              'Fill in the Blanks' ? null : currentQuestion?.question_answer?.[0]?.options
                  ?.length > 0 ? (
                <>
                  <div className='th-16 th-fw-600 th-black'>Options</div>
                  <div>
                    {currentQuestion?.question_answer?.[0]?.options?.map((obj, i) => {
                      return (
                        <div className=''>
                          <div className='text-justify'>
                            {`${String.fromCharCode(65 + i)}. `}
                            {`${obj[`option${i + 1}`]?.optionValue}`}
                          </div>
                          {obj[`option${i + 1}`]?.images?.length > 0 ? (
                            <div className='d-flex'>
                              {obj[`option${i + 1}`]?.images?.map((el) => {
                                return (
                                  <div className='pr-2'>
                                    <a
                                      onClick={() => {
                                        openPreview({
                                          currentAttachmentIndex: 0,
                                          attachmentsArray: [
                                            {
                                              src: getS3DomainURL(el),
                                              name: el,
                                              extension:
                                                '.' +
                                                el?.split('.')[
                                                  el?.split('.')?.length - 1
                                                ],
                                            },
                                          ],
                                        });
                                      }}
                                    >
                                      <EyeTwoTone />
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </div>{' '}
                </>
              ) : (
                ''
              )}

              {currentQuestion?.question_answer?.[0]?.answer ? (
                <>
                  {typeof currentQuestion?.question_answer?.[0]?.answer == 'object' ? (
                    <>
                      <div className='th-16 th-fw-600 th-black'>
                        {currentQuestion?.question_answer?.[0]?.answer?.length > 1
                          ? 'Answers'
                          : 'Answer'}
                      </div>
                      <div className='mb-3'>
                        {currentQuestion?.question_answer[0]?.answer?.map((obj, i) => {
                          let keyName =
                            currentQuestion?.question_answer[0]?.options?.filter(
                              (el) => obj in el
                            ).length > 0
                              ? Object.keys(
                                  currentQuestion?.question_answer[0]?.options?.filter(
                                    (el) => obj in el
                                  )[0]
                                )[0]
                              : '1';
                          return (
                            <div className='text-justify'>
                              {`${String.fromCharCode(64 + Number(keyName.slice(-1)))}. `}{' '}
                              {`${
                                currentQuestion?.question_answer[0]?.options?.filter(
                                  (el) => obj in el
                                ).length > 0
                                  ? currentQuestion?.question_answer[0]?.options?.filter(
                                      (el) => obj in el
                                    )[0]?.[obj]?.optionValue
                                  : obj
                              }`}
                              {currentQuestion?.question_answer[0]?.options?.filter(
                                (el) => obj in el
                              ).length > 0 ? (
                                <>
                                  <div className='d-flex'>
                                    {currentQuestion?.question_answer[0]?.options
                                      ?.filter((el) => obj in el)[0]
                                      ?.[obj]?.images?.map((el) => {
                                        return (
                                          <div className='pr-2'>
                                            <a
                                              onClick={() => {
                                                openPreview({
                                                  currentAttachmentIndex: 0,
                                                  attachmentsArray: [
                                                    {
                                                      src: getS3DomainURL(el),
                                                      name: el,
                                                      extension:
                                                        '.' +
                                                        el?.split('.')[
                                                          el?.split('.')?.length - 1
                                                        ],
                                                    },
                                                  ],
                                                });
                                              }}
                                            >
                                              <EyeTwoTone />
                                            </a>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          );
                        }) || ''}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='th-16 th-fw-600 th-black'>Answer</div>
                      <div className='mb-3'>
                        <div className='question-tiny text-justify'>
                          {ReactHtmlParser(currentQuestion?.question_answer[0]?.answer)}
                        </div>
                        {handleImageView(currentQuestion?.question_answer[0]?.answer)}
                      </div>
                    </>
                  )}
                </>
              ) : (
                ''
              )}

              {/* Sub Questions */}

              {currentQuestion?.sub_questions?.map((subQuestion, i) => (
                <>
                  <div className='sub-question-container'>
                    <div className='pt-3'>
                      {subQuestion?.question_answer?.map((p) => (
                        <>
                          {p?.question ? (
                            <div className='th-16 th-fw-500 th-black'>
                              Sub Question {i + 1} :
                            </div>
                          ) : (
                            ''
                          )}
                          <div className='question-tiny text-justify'>
                            {ReactHtmlParser(p?.question)}
                          </div>
                          {handleImageView(p?.question)}
                        </>
                      ))}
                    </div>
                    {subQuestion?.question_answer ??
                    subQuestion?.question_answer?.[0]?.options?.length > 0 ? (
                      <>
                        <div className='th-16 th-fw-600 th-black'>Options</div>
                        <div>
                          {subQuestion?.question_answer?.[0]?.options?.map((obj, i) => (
                            <div className=''>
                              {`${String.fromCharCode(65 + i)}. `}
                              {`${obj[`option${i + 1}`]?.optionValue}`}
                              {obj[`option${i + 1}`]?.images?.length > 0 ? (
                                <div className='d-flex'>
                                  {obj[`option${i + 1}`]?.images?.map((el) => {
                                    return (
                                      <div className='pr-2'>
                                        <a
                                          onClick={() => {
                                            openPreview({
                                              currentAttachmentIndex: 0,
                                              attachmentsArray: [
                                                {
                                                  src: getS3DomainURL(el),
                                                  name: el,
                                                  extension:
                                                    '.' +
                                                    el?.split('.')[
                                                      el?.split('.')?.length - 1
                                                    ],
                                                },
                                              ],
                                            });
                                          }}
                                        >
                                          <EyeTwoTone />
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ))}
                        </div>{' '}
                      </>
                    ) : (
                      ''
                    )}

                    {subQuestion?.question_answer[0]?.answer ? (
                      <>
                        {typeof subQuestion?.question_answer[0]?.answer == 'object' ? (
                          <>
                            <div className='th-16 th-fw-600 th-black'>
                              {subQuestion?.question_answer[0]?.answer?.length > 1
                                ? 'Answers'
                                : 'Answer'}
                            </div>
                            <div className='mb-3'>
                              {subQuestion?.question_answer[0]?.answer?.map((obj, i) => {
                                let keyName =
                                  subQuestion?.question_answer[0]?.options?.filter(
                                    (el) => obj in el
                                  ).length > 0
                                    ? Object.keys(
                                        subQuestion?.question_answer[0]?.options?.filter(
                                          (el) => obj in el
                                        )[0]
                                      )[0]
                                    : '1';
                                return (
                                  <div className='text-justify'>
                                    {`${String.fromCharCode(
                                      64 + Number(keyName.slice(-1))
                                    )}. `}{' '}
                                    {`${
                                      subQuestion?.question_answer[0]?.options?.filter(
                                        (el) => obj in el
                                      ).length > 0
                                        ? subQuestion?.question_answer[0]?.options?.filter(
                                            (el) => obj in el
                                          )[0]?.[obj]?.optionValue
                                        : obj
                                    }`}
                                    {subQuestion?.question_answer[0]?.options?.filter(
                                      (el) => obj in el
                                    ).length > 0 ? (
                                      <>
                                        <div className='d-flex'>
                                          {subQuestion?.question_answer[0]?.options
                                            ?.filter((el) => obj in el)[0]
                                            ?.[obj]?.images?.map((el) => {
                                              return (
                                                <div className='pr-2'>
                                                  <a
                                                    onClick={() => {
                                                      openPreview({
                                                        currentAttachmentIndex: 0,
                                                        attachmentsArray: [
                                                          {
                                                            src: getS3DomainURL(el),
                                                            name: el,
                                                            extension:
                                                              '.' +
                                                              el?.split('.')[
                                                                el?.split('.')?.length - 1
                                                              ],
                                                          },
                                                        ],
                                                      });
                                                    }}
                                                  >
                                                    <EyeTwoTone />
                                                  </a>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                );
                              }) || ''}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='th-16 th-fw-600 th-black'>Answer</div>
                            <div className='mb-3'>
                              <div className='question-tiny text-justify'>
                                {ReactHtmlParser(subQuestion?.question_answer[0]?.answer)}
                              </div>
                              {handleImageView(subQuestion?.question_answer[0]?.answer)}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default QuestionDetailsModal;

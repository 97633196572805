import React, { useState } from 'react';
import { Input } from 'antd';
import Mcq from './mcq';
import TrueFalse from './truefalse';
import FillBlanks from './fill-blanks';
import Descriptive from './descriptive';
import cuid from 'cuid';
import MyTinyEditor from '../tiny-mce-new';
const { TextArea } = Input;

const Question = ({
  questionTypeProps,
  questionData,
  questionList,
  setQuestions,
  parentIndex,
  comptype,
  childIndex
}) => {
  const [openEditor, setOpenEditor] = useState(true);
  const [question, setQuestion] = useState('');
  const handleEditorChange = (content, editor) => {
    setQuestion(content);
    let list = [...questionList];
    if(comptype !== 'comp'){
      list[parentIndex].question_answer[0]["question"] = content
      setQuestions(list)
    }else{
      let subQuestions = list[parentIndex].subquestions[childIndex]
      subQuestions.question_answer[0]['question'] = content;
      list[parentIndex].subquestions[childIndex] = subQuestions
      setQuestions(list)
    }
  };

  const handleContent = () => {
    let list = [...questionList];
    if(comptype === 'comp'){
      let subQuestions = list[parentIndex].subquestions[childIndex]
      let checkAnswer = subQuestions.question_answer[0]['question'] ? subQuestions.question_answer[0]['question'] : ''
      return checkAnswer
    } else{
      return question
    }
  }

  return (
    <>
      {/* <TextArea
        rows={4}
        placeholder='Questions goes here'
        style={{ backgroundColor: '#F1F4FB', color: '#B3B3B3' }}
      /> */}

      {openEditor && (
        <MyTinyEditor
          id={`questionEditor${cuid()}`}
          content={handleContent()}
          handleEditorChange={handleEditorChange}
          setOpenEditor={setOpenEditor}
          placeholder='Question goes here...'
        />
      )}

      {questionTypeProps?.children === 'Descriptive' && <Descriptive questionData = {questionData} questionList = {questionList} setQuestions = {setQuestions} parentIndex = {parentIndex} childIndex={childIndex} comptype={comptype} />}

      {questionTypeProps?.children === 'True False' && <TrueFalse questionData = {questionData} questionList = {questionList} setQuestions = {setQuestions} parentIndex = {parentIndex} childIndex={childIndex} comptype={comptype} />}

      {questionTypeProps?.children === 'MCQ' && <Mcq questionData = {questionData} questionList = {questionList} setQuestions = {setQuestions} parentIndex = {parentIndex} childIndex={childIndex} comptype={comptype} />}

      {/* {questionTypeProps?.children === 'MCQ_SINGLE_CHOICE' && <Mcq questionData = {questionData}/>} */}

      {questionTypeProps?.children === 'Fill In The Blanks' && <FillBlanks questionData = {questionData} questionList = {questionList} setQuestions = {setQuestions} parentIndex = {parentIndex} childIndex={childIndex} comptype={comptype}  />}
    </>
  );
};

export default Question;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import Cancelicon from '../../../assets/images/cross.svg';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { Button, SvgIcon, withStyles, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { generateQueryParamSting } from '../../../utility-functions';
// import  '../mapping.css';
const useStyles = makeStyles({
  cardRoots: {
    // marginLeft: 15,
    //  width: '0%',
    //  maxWidth: 0
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    color: ' #ff6b6b',
  },
  pos: {
    marginBottom: 12,
  },
  lastupdate: {
    fontSize: 14,
    color: '#014B7E',
  },
  subjectName: {
    float: 'right',
    color: '#014B7E',
    fontSize: 14,
  },
  gradeSub: {
    marginTop: '10px',
  },
  gradeName: {
    fontSize: 14,
    color: '#014B7E',
  },
  cardContent: {
    // marginTop: '25px'
  },
  gradeName: {
    marginTop: '16px',
  },
});

const StyledButton = withStyles({
  root: {
    height: '40px',
    color: '#FFFFFF',
    backgroundColor: (props) => props.backgroundcolor,
    '&:hover': {
      backgroundColor: '#FF6B6B',
    },
  },
})(Button);

export default function Subjectdeteils(props) {
  const classes = useStyles();
  const { setAlert } = React.useContext(AlertNotificationContext);
  const bull = <span className={classes.bullet}>•</span>;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [gradeSubjectList, setGradeSubjectList] = React.useState([]);
  const [activeData, setActiveData] = React.useState([]);
  const {
    selectedSchoolVerion,
    selectedSchool,
    selectedModule,
    selectedSchoolId,
    moduleKey,
    selectedSchoolObj,
    cardData,
  } = props;
  const { viewMoreList, cancelCard, fetchSchools = () => {} } = props;
  const [id, setid] = useState(null);

  const fetchDetails = () => {
    const { id: schoolId } = selectedSchool || {};
    const { key, value } = selectedModule || {};
    const queryString = generateQueryParamSting({
      school: selectedSchoolId,
      [key]: value,
      version_id: id,
      [moduleKey]: true,
    });
    const apiURL = `${endpoints.mapping.schoolList}?${queryString}`;
    axiosInstance
      .get(apiURL)
      .then((res) => {
        setGradeSubjectList(res?.data?.result);
        setActiveData(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setid(selectedSchoolVerion[0]?.academic_year);
  }, [selectedSchoolVerion]);

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [selectedSchoolVerion]);

  useEffect(() => {
    if (gradeSubjectList && gradeSubjectList.length > 0) {
      setActiveData(gradeSubjectList[0]);
      // setid(selectedSchoolVerion[0]?.academic_year);
    }
  }, [gradeSubjectList, cardData]);

  const deleteSubject = (schoolMappiingId, subjectId) => {
    const apiEndPoint =
      endpoints.mapping.deleteSubject.replace('<school_id>', schoolMappiingId) +
      '?subject_id=' +
      String(subjectId);
    axiosInstance
      .delete(apiEndPoint)
      .then((res) => {
        if (res.data.status_code >= 200 && res.data.status_code <= 299) {
          // setSchoolList(res.data.result);
          setAlert('success', 'Deleted Succesfully');
        } else {
          setAlert(
            'error',
            `${res.data.description || res.data.message || 'Failed to delete subject.'}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert('error', `${err.message}`);
      });
    fetchDetails();
  };

  const handleVersionwiseGrade = (each, index) => {
    fetchDetails(each);
    setActiveIndex(index);
  };

  useEffect(() => {
    if (id) {
      handleVersionwiseGrade();
      setActiveData(gradeSubjectList[0]);
    }
  }, []);

  return (
    <Card variant='outlined' className={classes.cardRoots}>
      <div className='cancel-icon' style={{ float: 'right', cursor: 'pointer' }}>
        <SvgIcon
          component={() => (
            <img
              style={{ width: '25px', margin: 10 }}
              src={Cancelicon}
              alt='given'
              onClick={cancelCard}
            />
          )}
        />
      </div>
      <CardContent className={classes.cardContent}>
        <div
          className='card-header'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #E2E2E2',
          }}
        >
          <Typography className={classes.title} gutterBottom>
            {selectedSchoolObj?.school_name}
          </Typography>
          <div className='card-last-update'>
            <Typography
              className={classes.lastupdate}
              variant='p'
              component='p'
              color='secondary'
            >
              Last Updated On
              <Typography className={classes.lastupdate}>
                {moment(selectedSchoolObj?.last_update).format('MMM DD YYYY')}
              </Typography>
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
        }}
      >
        {selectedSchoolVerion?.map((each, index) => (
          <div key={index}>
            <Tooltip title='Click to view the data' arrow>
              <StyledButton
                variant='primary'
                style={{
                  height: '30px',
                  width: '100px',
                  fontSize: 12,
                  marginRight: '5px',
                  marginTop: '5px',
                }}
                backgroundcolor={activeIndex === index ? '#FF6B6B' : 'blue'}
                onClick={() => {
                  handleVersionwiseGrade(each, index);
                  setid(each?.academic_year);
                }}
              >
                {each?.version_name}
              </StyledButton>
            </Tooltip>
          </div>
        ))}
      </CardContent>
      <div className='card-body'>
        <CardContent>
          {selectedSchoolVerion?.length > 0 ? (
            activeData?.grade_subject_mapping?.map((subAndGrade, index) => (
              <div style={{ borderBottom: '0.25px solid #E2E2E2' }} key={index}>
                <div
                  className='sub-name-icon'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography component={'h5'}>
                    {subAndGrade?.grade?.grade_name}
                  </Typography>
                  <Typography component={'p'}>
                    {subAndGrade?.subject?.subject_name}
                  </Typography>
                  <IconButton
                    title='Delete'
                    onClick={() => {
                      deleteSubject(activeData?.id, subAndGrade?.id);
                    }}
                    size='small'
                    className={classes.subjectName}
                  >
                    <DeleteOutlinedIcon style={{ color: '#ff6b6b' }} />
                  </IconButton>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography>No data</Typography>
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
}

import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import styles from './createTemplate.style';
const CreateTemplate = ({
  classes,
  templateFrom,
  onChange,
  dataList,
  checkNextPage,
  currentStep,
  clearPage,
  handleChooseTemplatesModal,
  clearAllData,
  setBool,
  history,
}) => {
  return (
    <>
      <Grid item sm={12} style={{ marginBottom: 10 }}>
        <Typography style={{ fontWeight: 'bold' }} className='font-size-14'>
          Enter Basic Details
        </Typography>
      </Grid>
      <Grid item container spacing={1} sm={12}>
        <Grid item container spacing={1} xs={12} alignItems='center'>
          <Grid item xs={4} md={2} sm={3}>
            <Typography className='font-size-12'>Title* (Max 100 characters)</Typography>
          </Grid>
          <Grid item xs={8} md={10} sm={9} lg={10}>
            <TextField
              className={[classes.number, classes.textField]}
              label=''
              margin='dense'
              fullWidth
              onChange={(e) => {
                if (e.target.value.length <= 100) {
                  onChange('title', e.target.value);
                }
              }}
              required
              value={templateFrom.title || ''}
              variant='outlined'
              // inputProps={{
              //   maxLength: 150,
              // }}
            />
          </Grid>
        </Grid>
        {history.location?.state?.data?.previousURL ===
          '/auto-assessment/question-paper' && (
          <Grid item container spacing={1} xs={12} alignItems='flex-start'>
            <Grid item xs={4} md={2} sm={3}>
              <Typography className='font-size-12' style={{ marginTop: 8 }}>
                Select*
              </Typography>
            </Grid>
            <Grid item container spacing={1} xs={8} md={10} sm={9}>
              <Grid item md={4} sm={4} xs={6} lg={4}>
                <Autocomplete
                  className={[classes.autoCompleate]}
                  options={dataList?.academicYearList || []}
                  getOptionLabel={(option) => option.session_year}
                  onChange={(e, newValue) => {
                    onChange('academic_year', newValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={templateFrom.academic_year}
                  size='small'
                  variant='outlined'
                  margin='dense'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Academic Year'
                      variant='outlined'
                      margin='dense'
                      name='academic_year'
                      className={[classes.textField]}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={6} lg={4}>
                <Autocomplete
                  className={[classes.autoCompleate]}
                  options={dataList.gradeList || []}
                  getOptionLabel={(option) => option.grade_name}
                  onChange={(e, newValue) => {
                    onChange('grade', newValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={templateFrom.grade}
                  size='small'
                  variant='outlined'
                  margin='dense'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Grade'
                      variant='outlined'
                      margin='dense'
                      name='grade'
                      className={[classes.textField]}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={6} lg={4}>
                <Autocomplete
                  className={[classes.autoCompleate]}
                  options={dataList.subjectList || []}
                  getOptionLabel={(option) => option.subject?.subject_name}
                  onChange={(e, newValue) => {
                    onChange('subject', newValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={templateFrom.subject}
                  size='small'
                  variant='outlined'
                  margin='dense'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Subject'
                      variant='outlined'
                      margin='dense'
                      name='subject'
                      className={[classes.textField]}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {history.location?.state?.data?.previousURL ===
          '/auto-assessment/question-paper' &&
          !templateFrom.hasOwnProperty('id') && (
            <Grid item container spacing={1} xs={12} alignItems='center'>
              <Grid item xs={4} md={2} sm={3}>
                <Typography className='font-size-12'>Select Template*</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      color='primary'
                      size='small'
                      variant={
                        templateFrom.selectTemplate === 1 ? 'contained' : 'outlined'
                      }
                      // disabled={templateFrom.selectTemplate === 1}
                      onClick={() => {
                        if (templateFrom.selectTemplate === 2) {
                          setBool(false);
                          setTimeout(() => {
                            let confirm = window.confirm(
                              'All changes will be lost. Do you want to start from the beginning?'
                            );
                            if (confirm) {
                              clearAllData();
                            } else {
                              setBool(true);
                            }
                          }, [100]);
                        }
                        onChange('selectTemplate', 1);
                      }}
                    >
                      Create Custom
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color='primary'
                      size='small'
                      variant={
                        templateFrom.selectTemplate === 2 ? 'contained' : 'outlined'
                      }
                      // disabled={templateFrom.selectTemplate === 2}
                      onClick={() => {
                        onChange('selectTemplate', 2);
                        handleChooseTemplatesModal();
                      }}
                    >
                      Choose Existing
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        {history.location?.state?.data?.previousURL ===
          '/auto-assessment/question-paper' && (
          <Grid item container spacing={1} xs={12} alignItems='flex-start'>
            <Grid item xs={4} md={2} sm={3}>
              <Typography className='font-size-12' style={{ marginTop: 8 }}>
                Select*
              </Typography>
            </Grid>
            <Grid item container spacing={1} xs={8} md={10} sm={9}>
              <Grid item md={4} sm={4} xs={6} lg={4}>
                <Autocomplete
                  className={[classes.autoCompleate]}
                  options={dataList.volumeList || []}
                  getOptionLabel={(option) => option?.volume_name}
                  onChange={(e, newValue) => {
                    onChange('volume', newValue);
                  }}
                  limitTags={2}
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={templateFrom?.volume}
                  size='small'
                  variant='outlined'
                  margin='dense'
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Volume'
                      variant='outlined'
                      margin='dense'
                      name='volume'
                      className={[classes.textField]}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={6} lg={4}>
                <Autocomplete
                  className={[classes.autoCompleate]}
                  options={dataList.chapterList || []}
                  getOptionLabel={(option) => option?.chapter_name}
                  onChange={(e, newValue) => {
                    onChange('chapter', newValue);
                  }}
                  limitTags={2}
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option?.id === value.id}
                  value={templateFrom?.chapter}
                  size='small'
                  variant='outlined'
                  margin='dense'
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Chapter'
                      variant='outlined'
                      margin='dense'
                      name='chapter'
                      className={[classes.textField]}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {history.location?.state?.data?.previousURL ===
          '/auto-assessment/question-paper' && (
          <Grid item container spacing={1} xs={12} alignItems='center'>
            <Grid item xs={4} md={2} sm={3}>
              <Typography className='font-size-12'>Select User Access Level*</Typography>
            </Grid>
            <Grid item container spacing={1} xs={8} md={10} sm={9}>
              <Grid item md={4} sm={4} xs={6} lg={4}>
                <Autocomplete
                  className={[classes.autoCompleate]}
                  options={dataList.accessLabelList || []}
                  getOptionLabel={(option) => option?.level_name}
                  onChange={(e, newValue) => {
                    onChange('access_usr_lvl', newValue);
                  }}
                  limitTags={2}
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option?.id === value.id}
                  value={templateFrom?.access_usr_lvl}
                  size='small'
                  variant='outlined'
                  margin='dense'
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Access Level'
                      variant='outlined'
                      margin='dense'
                      name='chapter'
                      className={[classes.textField]}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={1} xs={12} alignItems='center'>
          <Grid item xs={4} md={2} sm={3}>
            <Typography className='font-size-12'>Total Marks*</Typography>
          </Grid>
          <Grid item xs={3} md={2} sm={2} lg={1}>
            <TextField
              className={[classes.textField, classes.number]}
              label=''
              margin='dense'
              type='number'
              fullWidth
              // onKeyDown={(e) => {
              //   ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
              // }}
              onKeyDown={(e) => {
                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
                  e.preventDefault();
                }

                const currentValue = e.target.value;
                const newValue = currentValue + e.key;

                // Allow only up to 3 digits
                if (/^\d{4,}/.test(newValue)) {
                  e.preventDefault();
                }
              }}
              disabled={templateFrom.selectTemplate === 2}
              onChange={(e) => {
                onChange('total_marks', e.target.value);
              }}
              // onChange={(e) => {
              //   const inputValue = e.target.value;
              //   if (inputValue === '' || parseFloat(inputValue) > 0) {
              //     onChange('total_marks', inputValue);
              //   }
              // }}
              InputProps={{ inputProps: { min: 0 } }}
              required
              value={templateFrom.total_marks || ''}
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} alignItems='center'>
          <Grid item xs={4} md={2} sm={3}>
            <Typography className='font-size-12'>Duration (in Mins)*</Typography>
          </Grid>
          <Grid item xs={3} md={2} sm={2} lg={1}>
            <TextField
              className={[classes.textField, classes.number]}
              label=''
              margin='dense'
              type='number'
              fullWidth
              onKeyDown={(e) => {
                ['+', '-', 'e'].includes(e.key) && e.preventDefault();
              }}
              onChange={(e) => {
                onChange('duration', e.target.value);
              }}
              InputProps={{ inputProps: { min: 0 } }}
              required
              value={templateFrom.duration || ''}
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} alignItems='start'>
          <Grid item xs={4} md={2} sm={3}>
            <Typography className='font-size-12' style={{ marginTop: 5 }}>
              Instruction*
            </Typography>
          </Grid>
          <Grid item xs={8} md={10} sm={9}>
            <TextField
              className={[classes.textField]}
              label=''
              margin='dense'
              fullWidth
              rows={5}
              multiline
              onChange={(e) => {
                onChange('instruction', e.target.value);
              }}
              required
              value={templateFrom.instruction || ''}
              variant='outlined'
              InputProps={{ classes: { input: classes.textJustify } }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        sm={12}
        justify='flex-end'
        style={{ marginTop: '10px' }}
      >
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={() => clearPage(currentStep)}
          >
            Clear
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => checkNextPage(currentStep)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CreateTemplate);

import React, { useState, useEffect, useRef } from 'react';
import { Form, Select, message, Button, Input, Modal } from 'antd';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { FilterOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { difficultyLevels, bloomLevels } from '../../../generalQuestionConstants';
import CreateQuestion from '../CreateQuestion/CreateQuestion';

const { Option } = Select;
const Filters = ({
  fetchQuestionList,
  loading,
  academicYearList,
  gradeList,
  volumeList,
  boardList,
  questionTypeList,
  singleQuestion,
  tagStatus,
}) => {
  const formRef = useRef();
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [boardId, setBoardId] = useState();
  const [volumeId, setVolumeId] = useState();
  const [academicYearId, setAcademicYearId] = useState();
  const [gradeId, setGradeId] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState();
  const [moduleList, setModuleList] = useState([]);
  const [moduleId, setModuleId] = useState();
  const [chapterList, setChapterList] = useState([]);
  const [chapterId, setChapterId] = useState();
  const [keyConceptList, setKeyConceptList] = useState([]);
  const [keyConceptId, setKeyConceptId] = useState();
  const [questionTypeId, setQuestionTypeId] = useState();
  const [bloomLevel, setBloomLevel] = useState();
  const [difficultyLevel, setDifficultyLevel] = useState();
  const [showCreateQuestionModal, setShowCreateQuestionModal] = useState(false);

  // API Calls

  const handleCloseCreateQuestionModal = () => {
    setShowCreateQuestionModal(false);
  };
  const fetchSubjectList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchModuleList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.module}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setModuleList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchChapterList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.chapters}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setChapterList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchKeyConceptList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.keyConceptTableData}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setKeyConceptList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // Functions
  const handleSearchbyId = () => {
    if (!searchValue?.trim().length) {
      message.error('Please enter question ID');
      return;
    } else {
      setShowAllFilters(false);
      fetchQuestionList({
        pageNumber: 1,
        searchValue,
      });
    }
  };
  const handleBoardChange = (e) => {
    if (e) {
      setBoardId(e);
    } else {
      setBoardId();
    }
  };
  const handleVolumeChange = (e) => {
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setVolumeId(e);
      fetchModuleList({
        academic_year: academicYearId,
        volume: e,
        grade_subject: subjectId,
        board: boardId,
      });
    } else {
      setVolumeId();
    }
  };
  const handleAcademicYearChange = (e) => {
    setGradeId();
    setSubjectId();
    setSubjectList([]);
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      grade: null,
      subject: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setAcademicYearId(e);
      if (volumeId && boardId && subjectId) {
        fetchModuleList({
          academic_year: e,
          volume: volumeId,
          grade_subject: subjectId,
          board: boardId,
        });
      }
    } else {
      setAcademicYearId();
    }
  };
  const handleGradeChange = (e) => {
    setSubjectList([]);
    setSubjectId();
    setVolumeId();
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      subject: null,
      volume: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setGradeId(e);
      fetchSubjectList({ grade: e });
    } else {
      setGradeId();
    }
  };
  const handleSubjectChange = (e) => {
    setVolumeId();
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      volume: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setSubjectId(e);
    } else {
      setSubjectId();
    }
  };
  const handleModuleChange = (e) => {
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setModuleId(e);
      fetchChapterList({
        grade_subject: subjectId,
        volume: volumeId,
        board: boardId,
        lt_module: e,
      });
    } else {
      setModuleId();
    }
  };
  const handleChapterChange = (e) => {
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      key_concept: null,
    });
    if (e) {
      setChapterId(e);
      fetchKeyConceptList({ chapter: e });
    } else {
      setChapterId();
    }
  };
  const handleKeyConceptChange = (e) => {
    if (e) {
      setKeyConceptId(e);
    } else {
      setKeyConceptId();
    }
  };
  const handleQuestiontypeChange = (e) => {
    if (e) {
      setQuestionTypeId(e);
    } else {
      setQuestionTypeId();
    }
  };
  // Select Options
  const boardOptions = boardList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.board_name}
      </Option>
    );
  });
  const volumeOptions = volumeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.volume_name}
      </Option>
    );
  });
  const academicYearOptions = academicYearList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.session_year}
      </Option>
    );
  });
  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.grade_name}
      </Option>
    );
  });
  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.subject?.subject_name}
      </Option>
    );
  });
  const moduleOptions = moduleList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.lt_module_name}
      </Option>
    );
  });
  const chapterOptions = chapterList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.chapter_name}
      </Option>
    );
  });
  const keyConceptOptions = keyConceptList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.topic_name}
      </Option>
    );
  });
  const bloomLevelOptions = bloomLevels?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.level}
      </Option>
    );
  });
  const difficultyLevelOptions = difficultyLevels?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.level}
      </Option>
    );
  });
  const questionTypeOptions = questionTypeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.type_name}
      </Option>
    );
  });

  return (
    <>
      <Form ref={formRef} layout='vertical'>
        <div className='row align-items-center'>
          <div className='col-sm-3 col-6'>
            <Form.Item name='academic_year' label='Academic Year*'>
              <Select
                showSearch
                placeholder='Select Academic Year*'
                optionFilterProp='children'
                className=''
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={(e) => {
                  handleAcademicYearChange(e);
                }}
              >
                {academicYearOptions}
              </Select>
            </Form.Item>
          </div>
          <div className='col-sm-3 col-6'>
            <Form.Item name='grade' label='Grade*'>
              <Select
                showSearch
                placeholder='Select Grade*'
                optionFilterProp='children'
                className=''
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={(e) => {
                  handleGradeChange(e);
                }}
              >
                {gradeOptions}
              </Select>
            </Form.Item>
          </div>
          <div className='col-sm-3 col-6 pr-1'>
            <Form.Item name='subject' label='Subject*'>
              <Select
                showSearch
                placeholder='Select Subject*'
                optionFilterProp='children'
                className=''
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={(e) => {
                  handleSubjectChange(e);
                }}
              >
                {subjectOptions}
              </Select>
            </Form.Item>
          </div>
          {showAllFilters && (
            <>
              <div className='col-sm-3 col-6'>
                <Form.Item name='baord' label='Board'>
                  <Select
                    showSearch
                    allowClear
                    placeholder='Select Board'
                    optionFilterProp='children'
                    className=''
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleBoardChange(e);
                    }}
                  >
                    {boardOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='volume' label='Volume'>
                  <Select
                    showSearch
                    placeholder='Select Volume'
                    optionFilterProp='children'
                    className=''
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleVolumeChange(e);
                    }}
                  >
                    {volumeOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='module' label='Module'>
                  <Select
                    showSearch
                    allowClear
                    placeholder='Select Module'
                    optionFilterProp='children'
                    className=''
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleModuleChange(e);
                    }}
                  >
                    {moduleOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='chapter' label='Chapter'>
                  <Select
                    showSearch
                    placeholder='Select Chapter'
                    optionFilterProp='children'
                    className=''
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleChapterChange(e);
                    }}
                  >
                    {chapterOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='key_concept' label='Key Concept'>
                  <Select
                    showSearch
                    placeholder='Select Key Concept'
                    optionFilterProp='children'
                    className=''
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleKeyConceptChange(e);
                    }}
                  >
                    {keyConceptOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='blooms_level' label='Blooms Level'>
                  <Select
                    placeholder='Blooms Level'
                    optionFilterProp='children'
                    className=''
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      if (e) {
                        setBloomLevel(e);
                      } else {
                        setBloomLevel();
                      }
                    }}
                  >
                    {bloomLevelOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='difficulty_level' label='Difficulty Level'>
                  <Select
                    placeholder='Difficulty Level'
                    optionFilterProp='children'
                    className=''
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      if (e) {
                        setDifficultyLevel(e);
                      } else {
                        setDifficultyLevel();
                      }
                    }}
                  >
                    {difficultyLevelOptions}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-3 col-6'>
                <Form.Item name='question_type' label='Question Type'>
                  <Select
                    placeholder='Question Type'
                    optionFilterProp='children'
                    className=''
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={handleQuestiontypeChange}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {questionTypeOptions}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          <div className='col-sm-2 col-6'>
            <Button
              type='primary'
              className='th-br-4 w-100 mt-sm-3'
              loading={!singleQuestion && loading}
              onClick={() => {
                setSearchValue();
                fetchQuestionList({
                  pageNumber: 1,
                  boardId,
                  volumeId,
                  academicYearId,
                  gradeId,
                  subjectId,
                  chapterId,
                  keyConceptId,
                  bloomLevel,
                  difficultyLevel,
                  questionTypeId,
                });
              }}
            >
              Filter
            </Button>
          </div>
          <div className='col-1 pl-0'>
            <Button
              type='link'
              className='th-br-4 mt-sm-3 pl-0 th-12'
              size='small'
              icon={<FilterOutlined />}
              onClick={() => setShowAllFilters((showAllFilters) => !showAllFilters)}
            >
              <span className=''>Show {showAllFilters ? 'Less' : 'All'}</span>
            </Button>
          </div>
        </div>
      </Form>
      <div className='row pt-2'>
        <div className='col-3'>
          <Input
            type='number'
            value={searchValue}
            placeholder='Search Question by ID'
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSearchbyId();
              }
            }}
          />
        </div>
        <div className='col-3'>
          <Button
            type='primary'
            className='th-br-4 w-100'
            loading={singleQuestion && loading}
            onClick={() => {
              handleSearchbyId();
            }}
          >
            Search
          </Button>
        </div>
        {tagStatus && (
          <>
            <div className='col-3'></div>
            <div className='col-3'>
              <Button
                type='primary'
                className='th-br-4 w-100'
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setShowCreateQuestionModal(true);
                }}
              >
                Create Question
              </Button>
            </div>
          </>
        )}
      </div>
      {showCreateQuestionModal && (
        <Modal
          open={showCreateQuestionModal}
          zIndex={1400}
          maskClosable={false}
          title={
            <div className='d-flex justify-content-between px-2'>
              <span>Create New Question</span>
              <span
                className='th-fw-700 th-18 th-pointer'
                onClick={() => {
                  handleCloseCreateQuestionModal();
                }}
              >
                X
              </span>
            </div>
          }
          closable={null}
          footer={null}
          width={'70vw'}
          centered
          className='th-upload-modal'
          onCancel={handleCloseCreateQuestionModal}
        >
          <div style={{ height: '82vh', overflowY: 'scroll' }}>
            <CreateQuestion
              handleCloseCreateQuestionModal={handleCloseCreateQuestionModal}
              academicYearList={academicYearList}
              gradeList={gradeList}
              volumeList={volumeList}
              boardList={boardList}
              questionTypeList={questionTypeList}
              selectedAcademicYearId={academicYearId}
              selectedGradeId={gradeId}
              selectedSubjectId={subjectId}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Filters;
